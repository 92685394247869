import React, {
  CSSProperties,
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './result.scss';
import Button from '../button/button';
import TextInput from '../text-input/text-input';
import {
  PaintCalculatorDispatch,
  PaintCalculatorState,
  PaintCalculatorStore,
} from '../../context/paint-calculator.context';
import { Translations, useTranslations } from '../../hooks/use-translations';
import { forceNumeric, roundMath } from '../../../shared/utils/forceNumeric';
import { COATS_INPUT_LIMITS, DEBOUNCE_TIME } from '../../constants/constants';

interface ResultsAreaProps {
  store: PaintCalculatorStore;
  translations: Translations;
  location: 'wall' | 'ceiling' | 'trim';
  coats: number;
  areaFormated: number;
  volumeFormated: number;
  handleInputChange: (id: string, value: string | number) => void;
  isError?: boolean;
}

export interface ResultProps {
  isError?: boolean;
}

type ResultState = Partial<
  Record<
    'wall' | 'trim' | 'ceiling',
    {
      coats: number;
      area: number;
      volume: number;
    }
  >
>;

const ResultArea: FC<ResultsAreaProps> = ({
  store,
  translations,
  location,
  coats,
  areaFormated,
  volumeFormated,
  handleInputChange,
  isError,
}) => {
  const outerArea = useRef<HTMLDivElement>(null);
  const innerArea = useRef<HTMLSpanElement>(null);
  const outerVolume = useRef<HTMLDivElement>(null);
  const innerVolume = useRef<HTMLSpanElement>(null);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [fontSize, setFontSize] = useState(0);
  let title, totalArea, totalVolume, unitOfArea, unitOfVolume;
  switch (location) {
    case 'ceiling':
      title = translations.CEILING_RESULTS;
      totalArea = translations.TotalCeilingArea;
      totalVolume = store.paintType === 'paint' ? translations.TotalCeilingPaint : translations.TotalCeilingPrimer;
      break;
    case 'trim':
      title = translations.TRIM_RESULTS;
      totalArea = translations.TotalTrimArea;
      totalVolume = store.paintType === 'paint' ? translations.TotalTrimPaint : translations.TotalTrimPrimer;
      break;
    case 'wall':
      title = translations.WALL_RESULTS;
      totalArea = translations.TotalWallArea;
      totalVolume = store.paintType === 'paint' ? translations.TotalWallPaint : translations.TotalWallPrimer;
      break;
  }
  switch (store.unit ?? 'imperial') {
    case 'imperial':
      unitOfArea = translations.ImperialUnitOfArea;
      unitOfVolume = translations.ImperialUnitOfVolume;
      break;
    case 'metric':
      unitOfArea = translations.MetricUnitOfArea;
      unitOfVolume = translations.MetricUnitOfVolume;
      break;
  }

  const triggerSizeCheck = useCallback(() => {
    if (timer) clearTimeout(timer);
    setTimer(setTimeout(() => setFontSize(0), DEBOUNCE_TIME));
  }, [timer]);

  useEffect(() => {
    if (outerArea.current && innerArea.current && outerVolume.current && innerVolume.current) {
      const outerAreaWith = outerArea.current.offsetWidth;
      const innerAreaWith = innerArea.current.offsetWidth;
      const outerVolumeWith = outerVolume.current.offsetWidth;
      const innerVolumeWith = innerVolume.current.offsetWidth;
      const currentFontSize = parseFloat(window.getComputedStyle(innerArea.current).fontSize);
      if (innerAreaWith > outerAreaWith || innerVolumeWith > outerVolumeWith) {
        setFontSize(currentFontSize - 0.25);
      } else {
        setFontSize(currentFontSize);
      }
    }
  }, [coats, fontSize]);

  useEffect(() => {
    const controller = new AbortController();
    window.addEventListener(
      'resize',
      () => {
        triggerSizeCheck();
      },
      { signal: controller.signal },
    );
    return () => controller.abort();
  }, [triggerSizeCheck]);

  return (
    <section className={`pc-result__${location}`}>
      {location !== 'wall' && <hr />}
      <h4>{title}</h4>
      <TextInput
        id={`number-of-coats-${location}`}
        type='number'
        inputMode='numeric'
        supLabel={translations.NumberOfCoats}
        isError={isError}
        callBack={(id, value) => handleInputChange(id, value)}
        value={coats}
        min={COATS_INPUT_LIMITS.MIN}
        max={COATS_INPUT_LIMITS.MAX}
        step={COATS_INPUT_LIMITS.STEP}
      />
      <h5>{totalArea}</h5>
      <div
        ref={outerArea}
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        style={fontSize > 0 ? ({ '--pc-result-value-font-size': `${fontSize}px` } as CSSProperties) : undefined}
      >
        <span ref={innerArea}>{areaFormated}</span> <span>{unitOfArea}</span>
      </div>
      <h5>{totalVolume}</h5>
      <div
        ref={outerVolume}
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        style={fontSize > 0 ? ({ '--pc-result-value-font-size': `${fontSize}px` } as CSSProperties) : undefined}
      >
        <span ref={innerVolume}>{volumeFormated}</span>
        <span>{unitOfVolume}</span>
      </div>
    </section>
  );
};

const Result: FC<ResultProps> = ({ isError }) => {
  const [resultState, setResultState] = useState<ResultState>({});
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);

  const resultTypes: ['wall', 'ceiling', 'trim'] | ['wall', 'trim'] = useMemo(
    () => (store.space === 'interior' ? ['wall', 'ceiling', 'trim'] : ['wall', 'trim']),
    [store.space],
  );

  const handleStartOver = useCallback(() => {
    if (dispatch) {
      dispatch({ type: 'CLEAR_ALL' });
    }
  }, [dispatch]);

  const handleInputChange = useCallback(
    (id: string, value: string | number) => {
      if (!dispatch) return;
      value = forceNumeric(value);
      switch (id) {
        case 'number-of-coats-wall':
          dispatch({
            type: store.calculation === 'quick' ? 'SET_QUICK_AREA_COATS' : 'SET_CUSTOM_AREA_COATS',
            coats: value,
          });
          break;
        case 'number-of-coats-ceiling':
          dispatch({
            type: store.calculation === 'quick' ? 'SET_QUICK_CEILING_COATS' : 'SET_CUSTOM_CEILING_COATS',
            coats: value,
          });
          break;
        case 'number-of-coats-trim':
          dispatch({
            type: 'SET_CUSTOM_TRIM_COATS',
            coats: value,
          });
          break;
        default:
          console.warn(`handleInputChangeCustom Unknown id`, id, value);
      }
    },
    [dispatch, store.calculation],
  );

  useEffect(() => {
    resultTypes.map((location) => {
      const calculation = store.calculation ?? 'quick';
      let coats = 0;
      let areaFormated = 0;
      let areaFormatedDsp = 0;
      let volumeFormated = 0;
      let volumeFormatedDsp = 0;
      switch (location) {
        case 'wall':
          coats = store[calculation]?.area?.coats ?? 1;
          areaFormated = store[calculation]?.area?.area ?? 0;
          volumeFormated = store[calculation]?.area?.coverage ?? 0;
          break;
        case 'trim':
          if (calculation === 'custom') {
            coats = store[calculation]?.[location]?.coats ?? 1;
            areaFormated = store[calculation]?.[location]?.area ?? 0;
            volumeFormated = store[calculation]?.[location]?.coverage ?? 0;
          }
          break;
        case 'ceiling':
          coats = store[calculation]?.[location]?.coats ?? 1;
          areaFormated = store[calculation]?.[location]?.area ?? 0;
          volumeFormated = store[calculation]?.[location]?.coverage ?? 0;
          break;
      }
      areaFormatedDsp = areaFormated > 0 ? Math.max(roundMath(areaFormated, 1), 0.1) : 0;
      volumeFormatedDsp = volumeFormated > 0 ? Math.max(roundMath(volumeFormated * coats, 1), 0.1) : 0;
      setResultState((prevState) => ({
        ...prevState,
        [location]: { coats, area: areaFormatedDsp, volume: volumeFormatedDsp },
      }));
    });
  }, [resultTypes, store]);

  return (
    <div className='pc-result'>
      <>
        {resultTypes.map((location, index) => {
          if (resultState[location]?.area === 0 || resultState[location]?.volume === 0) return <Fragment key={index} />;
          return (
            <ResultArea
              key={index}
              store={store}
              translations={translations}
              location={location}
              coats={resultState[location]?.coats ?? 1}
              areaFormated={resultState[location]?.area ?? 0}
              handleInputChange={handleInputChange}
              volumeFormated={resultState[location]?.volume ?? 0}
              isError={isError}
            />
          );
        })}
        <section className='pc-result__buttons'>
          <Button
            style='button'
            isInternal
            route={`/${store.calculation}/${store.space}/${store.paintType}/${store.unit}`}
          >
            {translations.Recalculate}
          </Button>
          <Button style='button' isInverse isInternal callback={handleStartOver} route='/measurement-picker'>
            {translations.StartOver}
          </Button>
        </section>
      </>
    </div>
  );
};

export default Result;
