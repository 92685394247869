import React, { FC, ReactNode } from 'react';
import './title.scss';

export interface TitleProps {
  text: ReactNode;
  children?: ReactNode;
}

const Title: FC<TitleProps> = ({ text, children }) => {
  return (
    <div className='pc-title'>
      <>
        <h3>{text}</h3>
        {children}
      </>
    </div>
  );
};

export default Title;
