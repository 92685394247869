import React, { FC, ReactNode } from 'react';
import './infoIcon.scss';
import { Link, useInRouterContext } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

export interface IconProps {
  route: string;
  isInternal?: boolean;
  tooltipText?: string | ReactNode;
  children?: ReactNode;
}

const InfoIcon: FC<IconProps> = ({ route, isInternal = true, tooltipText, children }) => {
  const inRouterContext = useInRouterContext();

  return (
    <>
      {inRouterContext && isInternal ? (
        <Link to={route} className='pc-info-icon'>
          {children}
        </Link>
      ) : (
        <a href={route} className='pc-info-icon'>
          {children}
        </a>
      )}
      <Tooltip className='pc-info-icon-tooltip' anchorSelect='.pc-info-icon'>
        {tooltipText}
      </Tooltip>
    </>
  );
};

export default InfoIcon;
