import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import './window.scss';
import Checkbox from '../checkbox/checkbox';
import TextInput from '../text-input/text-input';
import { FaMinusCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { forceNumeric } from '../../../shared/utils/forceNumeric';
import { useTranslations } from '../../hooks/use-translations';

export interface WindowProps {
  wallId: string;
  windowId: string;
  isError?: boolean;
}

const Window: FC<WindowProps> = ({ wallId, windowId, isError }) => {
  const [unifiedIsError, setUnifiedIsError] = useState<boolean>(false);
  const [localIsError, setLocalIsError] = useState<boolean>(false);
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);

  const handleTitleClick = useCallback(
    (wallId: string, windowId: string) => {
      if (dispatch) {
        dispatch({ type: 'CLEAR_CUSTOM_WINDOW', wallId, windowId });
      }
    },
    [dispatch],
  );

  const handleInputChange = useCallback(
    (wallId: string, windowId: string, id: string, value: string | number) => {
      if (!dispatch) return;
      value = forceNumeric(value);
      switch (id) {
        case `${windowId}-height-big`:
          dispatch({ type: 'SET_CUSTOM_WINDOW_HEIGHT_BIG', wallId, windowId, value });
          break;
        case `${windowId}-height-small`:
          dispatch({ type: 'SET_CUSTOM_WINDOW_HEIGHT_SMALL', wallId, windowId, value });
          break;
        case `${windowId}-width-big`:
          dispatch({ type: 'SET_CUSTOM_WINDOW_WIDTH_BIG', wallId, windowId, value });
          break;
        case `${windowId}-width-small`:
          dispatch({ type: 'SET_CUSTOM_WINDOW_WIDTH_SMALL', wallId, windowId, value });
          break;
        case `${windowId}-has-trim`:
          dispatch({ type: 'SET_CUSTOM_WINDOW_HAS_TRIM', wallId, windowId });
          break;
        case `${windowId}-trim-small`:
          dispatch({ type: 'SET_CUSTOM_WINDOW_TRIM_SMALL', wallId, windowId, value });
          break;
        default:
          console.warn(`handleInputChange Unknown id`, wallId, windowId, id, value);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setLocalIsError(!!store.custom?.wall?.[wallId].window?.[windowId].isError || false);
    setUnifiedIsError(!!isError || !!store.custom?.wall?.[wallId].window?.[windowId].isError || false);
  }, [windowId, isError, store.custom?.wall, wallId]);

  return (
    <div className={`pc-window pc-window--${windowId}`}>
      <section className='pc-window__title'>
        <h4 role='button' onClick={() => handleTitleClick(wallId, windowId)} tabIndex={0}>
          <span>{translations.WINDOW}</span>
          <FaMinusCircle />
        </h4>
      </section>
      <section className='pc-window__dimentions'>
        <TextInput
          id={`${windowId}-height-big`}
          type='text'
          inputMode='numeric'
          supLabel={translations.Height}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.window?.[windowId]?.height?.big ?? '0'}
          callBack={(id, value) => handleInputChange(wallId, windowId, id, value)}
        />
        <TextInput
          id={`${windowId}-height-small`}
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.window?.[windowId]?.height?.small ?? '0'}
          callBack={(id, value) => handleInputChange(wallId, windowId, id, value)}
        />
        <TextInput
          id={`${windowId}-width-big`}
          type='text'
          inputMode='numeric'
          supLabel={translations.Width}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.window?.[windowId]?.width?.big ?? '0'}
          callBack={(id, value) => handleInputChange(wallId, windowId, id, value)}
        />
        <TextInput
          id={`${windowId}-width-small`}
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.window?.[windowId]?.width?.small ?? '0'}
          callBack={(id, value) => handleInputChange(wallId, windowId, id, value)}
        />
      </section>
      <section className='pc-window__trim'>
        <Checkbox
          id={`${windowId}-has-trim`}
          isChecked={!!store.custom?.wall?.[wallId]?.window?.[windowId]?.hasTrim}
          callback={() => {
            handleInputChange(wallId, windowId, `${windowId}-has-trim`, '');
          }}
        >
          {translations.WindowHasTrim}
        </Checkbox>
        {!!store.custom?.wall?.[wallId]?.window?.[windowId]?.hasTrim && (
          <TextInput
            id={`${windowId}-trim-small`}
            type='text'
            inputMode='numeric'
            supLabel={translations.TrimWidth}
            subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
            isError={unifiedIsError}
            value={store.custom?.wall?.[wallId]?.window?.[windowId]?.trim?.small ?? '0'}
            callBack={(id, value) => handleInputChange(wallId, windowId, id, value)}
          />
        )}
      </section>
      {localIsError && (
        <section className='pc-window__error'>
          <FormattedMessage defaultMessage='The total window area exceeds your total wall area. Please recheck your measurements and try again.' />
        </section>
      )}
    </div>
  );
};

export default Window;
