import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import './door.scss';
import Checkbox from '../checkbox/checkbox';
import TextInput from '../text-input/text-input';
import { FaMinusCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { useTranslations } from '../../hooks/use-translations';
import { forceNumeric } from '../../../shared/utils/forceNumeric';

export interface DoorProps {
  wallId: string;
  doorId: string;
  isError?: boolean;
}

const Door: FC<DoorProps> = ({ wallId, doorId, isError }) => {
  const [unifiedIsError, setUnifiedIsError] = useState<boolean>(false);
  const [localIsError, setLocalIsError] = useState<boolean>(false);
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);

  const handleTitleClick = useCallback(
    (wallId: string, doorId: string) => {
      if (dispatch) {
        dispatch({ type: 'CLEAR_CUSTOM_DOOR', wallId, doorId });
      }
    },
    [dispatch],
  );

  const handleInputChange = useCallback(
    (wallId: string, doorId: string, id: string, value: string | number) => {
      if (!dispatch) return;
      value = forceNumeric(value);
      switch (id) {
        case `${doorId}-height-big`:
          dispatch({ type: 'SET_CUSTOM_DOOR_HEIGHT_BIG', wallId, doorId, value });
          break;
        case `${doorId}-height-small`:
          dispatch({ type: 'SET_CUSTOM_DOOR_HEIGHT_SMALL', wallId, doorId, value });
          break;
        case `${doorId}-width-big`:
          dispatch({ type: 'SET_CUSTOM_DOOR_WIDTH_BIG', wallId, doorId, value });
          break;
        case `${doorId}-width-small`:
          dispatch({ type: 'SET_CUSTOM_DOOR_WIDTH_SMALL', wallId, doorId, value });
          break;
        case `${doorId}-has-trim`:
          dispatch({ type: 'SET_CUSTOM_DOOR_HAS_TRIM', wallId, doorId });
          break;
        case `${doorId}-trim-small`:
          dispatch({ type: 'SET_CUSTOM_DOOR_TRIM_SMALL', wallId, doorId, value });
          break;
        default:
          console.warn(`handleInputChange Unknown id`, wallId, doorId, id, value);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setLocalIsError(!!store.custom?.wall?.[wallId].door?.[doorId].isError || false);
    setUnifiedIsError(!!isError || !!store.custom?.wall?.[wallId].door?.[doorId].isError || false);
  }, [doorId, isError, store.custom?.wall, wallId]);

  return (
    <div className={`pc-door pc-door--${doorId}`}>
      <section className='pc-door__title'>
        <h4 role='button' onClick={() => handleTitleClick(wallId, doorId)} tabIndex={0}>
          <span>{translations.DOOR}</span>
          <FaMinusCircle />
        </h4>
      </section>
      <section className='pc-door__dimentions'>
        <TextInput
          id={`${doorId}-height-big`}
          type='text'
          inputMode='numeric'
          supLabel={translations.Height}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.door?.[doorId]?.height?.big}
          callBack={(id, value) => handleInputChange(wallId, doorId, id, value)}
        />
        <TextInput
          id={`${doorId}-height-small`}
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.door?.[doorId]?.height?.small}
          callBack={(id, value) => handleInputChange(wallId, doorId, id, value)}
        />
        <TextInput
          id={`${doorId}-width-big`}
          type='text'
          inputMode='numeric'
          supLabel={translations.Width}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.door?.[doorId]?.width?.big}
          callBack={(id, value) => handleInputChange(wallId, doorId, id, value)}
        />
        <TextInput
          id={`${doorId}-width-small`}
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.door?.[doorId]?.width?.small}
          callBack={(id, value) => handleInputChange(wallId, doorId, id, value)}
        />
      </section>
      <section className='pc-door__trim'>
        <Checkbox
          id={`${doorId}-has-trim`}
          isError={unifiedIsError}
          isChecked={!!store.custom?.wall?.[wallId]?.door?.[doorId]?.hasTrim}
          callback={() => {
            handleInputChange(wallId, doorId, `${doorId}-has-trim`, '');
          }}
        >
          {translations.DoorHasTrim}
        </Checkbox>
        {!!store.custom?.wall?.[wallId]?.door?.[doorId].hasTrim && (
          <TextInput
            id={`${doorId}-trim-small`}
            type='text'
            inputMode='numeric'
            supLabel={translations.trimWidth}
            subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
            isError={unifiedIsError}
            value={store.custom?.wall?.[wallId]?.door?.[doorId]?.trim?.small}
            callBack={(id, value) => handleInputChange(wallId, doorId, id, value)}
          />
        )}
      </section>
      {localIsError && (
        <section className='pc-door__error'>
          <FormattedMessage defaultMessage='The total door area exceeds your total wall area. Please recheck your measurements and try again.' />
        </section>
      )}
    </div>
  );
};

export default Door;
