import React, { ChangeEvent, FC, HTMLAttributes, HTMLInputTypeAttribute, ReactNode, useEffect, useState } from 'react';
import './text-input.scss';
import { DEBOUNCE_TIME, NUMERIC_INPUT_LIMITS } from '../../constants/constants';
import { forceNumeric, roundDown } from '../../../shared/utils/forceNumeric';

export interface TextInputProps {
  id: string;
  supLabel?: string | ReactNode;
  subLabel?: string | ReactNode;
  isError?: boolean;
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  inputMode: HTMLAttributes<string>['inputMode'];
  value?: string | number;
  callBack?: (id: string, value: string | number) => void;
  min?: number;
  max?: number;
  step?: number;
}

const TextInput: FC<TextInputProps> = ({
  id,
  supLabel,
  subLabel,
  placeholder,
  isError,
  type,
  inputMode,
  value,
  callBack,
  min = NUMERIC_INPUT_LIMITS.MIN,
  max = NUMERIC_INPUT_LIMITS.MAX,
  step = NUMERIC_INPUT_LIMITS.STEP,
}) => {
  const [inputValue, setInputValue] = useState<string | number | null>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (callBack && (inputValue === '' || inputValue)) {
        if (inputMode === 'numeric' || inputMode === 'decimal') {
          let checkMin, checkMax;

          const newInputValue = forceNumeric(inputValue);
          if (newInputValue >= (min ?? 0)) {
            checkMin = newInputValue;
          } else {
            checkMin = min ?? 0;
          }
          if (typeof max === 'number') {
            if (checkMin <= max) {
              checkMax = checkMin;
            } else {
              checkMax = max;
            }
          } else {
            checkMax = checkMin;
          }

          const valueToUse =
            inputMode === 'numeric' ? roundDown(checkMax) : inputMode === 'decimal' ? forceNumeric(checkMax) : checkMax;
          setInputValue(valueToUse);
          callBack(id, valueToUse);
        } else {
          setInputValue(inputValue);
          callBack(id, inputValue);
        }
      }
    }, DEBOUNCE_TIME);
    return () => {
      clearTimeout(timer);
    };
  }, [callBack, id, inputMode, inputValue, min, max]);

  return (
    <label className={`pc-text-input${isError ? ' pc-text-input--is-error' : ''}`} htmlFor={id}>
      {supLabel && <span>{supLabel}</span>}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        inputMode={inputMode}
        value={inputValue ?? (inputMode === 'numeric' ? 0 : '')}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
      />
      {subLabel && <span>{subLabel}</span>}
    </label>
  );
};

export default TextInput;
