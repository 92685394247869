import React, { FC, useContext } from 'react';
import Disclaimer from './disclaimer';
import { CONSTANTS } from '../../constants/constants';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorState } from '../../context/paint-calculator.context';
import { useTranslations } from '../../hooks/use-translations';

const CustomDisclaimerWrapper: FC = () => {
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);

  return (
    <section className='pc-custom__disclaimer'>
      <Disclaimer>
        <FormattedMessage
          defaultMessage='The results of this calculation are for one coat of {space} {paintType}. It assumes {containerSize} {containerUnit} will cover approximately {coverage} {coverageUnit}. To ensure optimal coverage, we recommend rounding up to the nearest {containerUnit}. For specific product coverage rates, please read the Product Data Sheet (PDS) for the applicable product.'
          values={{
            space: store.space === 'interior' ? translations.interior : translations.exterior,
            paintType: store.paintType === 'paint' ? translations.paint : translations.primer,
            containerSize: CONSTANTS[store.unit === 'imperial' ? 'IMPERIAL' : 'METRIC']?.VOLUME,
            containerUnit: store.unit === 'imperial' ? translations.gallon : translations.liter,
            coverage:
              CONSTANTS[store.unit === 'imperial' ? 'IMPERIAL' : 'METRIC']?.COVERAGE[
                store.paintType === 'primer' ? 'PRIMER' : store.space === 'interior' ? 'INTERIOR' : 'EXTERIOR'
              ],
            coverageUnit: store.unit === 'imperial' ? translations.squareFeet : translations.squareMeters,
          }}
        />
        <FormattedMessage defaultMessage='The paint calculator is intended to provide the user with the approximate amount of product needed for a project. Product requirements will vary according to factors such as application method and surface condition.' />
      </Disclaimer>
    </section>
  );
};

export default CustomDisclaimerWrapper;
