export type Texture = Partial<Record<'FINE' | 'MEDIUM' | 'EXTRA_COARSE', number>>;

export type Coverage = Partial<Record<'PRIMER' | 'EXTERIOR' | 'INTERIOR', number>>;

export interface OtherKeys {
  CONVERSION: number;
  VOLUME: number;
  COVERAGE: Coverage;
  TEXTURE: Texture;
}

export interface Values {
  HEIGHT: { BIG: number; SMALL: number };
  WIDTH: { BIG: number; SMALL: number };
}

export type Opening = Partial<Record<'DOOR' | 'WINDOW' | 'OPENING', Values>>;

export type Type = Partial<Record<'QUICK' | 'CUSTOM', Opening>>;

export type Constants = Partial<Record<'IMPERIAL' | 'METRIC', Type & OtherKeys>>;

export const CONSTANTS: Constants = {
  IMPERIAL: {
    QUICK: {
      DOOR: { HEIGHT: { BIG: 7, SMALL: 0 }, WIDTH: { BIG: 3, SMALL: 0 } },
      WINDOW: { HEIGHT: { BIG: 5, SMALL: 0 }, WIDTH: { BIG: 3, SMALL: 0 } },
    },
    CONVERSION: 12,
    VOLUME: 1,
    COVERAGE: {
      PRIMER: 250,
      EXTERIOR: 350,
      INTERIOR: 400,
    },
    TEXTURE: { FINE: 1, MEDIUM: 2, EXTRA_COARSE: 3 },
  },
  METRIC: {
    QUICK: {
      DOOR: { HEIGHT: { BIG: 2, SMALL: 10 }, WIDTH: { BIG: 0, SMALL: 90 } },
      WINDOW: { HEIGHT: { BIG: 1, SMALL: 50 }, WIDTH: { BIG: 0, SMALL: 90 } },
    },
    CONVERSION: 100,
    VOLUME: 3.8,
    COVERAGE: {
      PRIMER: 23.4,
      EXTERIOR: 32.7,
      INTERIOR: 37.4,
    },
    TEXTURE: { FINE: 1, MEDIUM: 2, EXTRA_COARSE: 3 },
  },
};

export const COATS_INPUT_LIMITS: Record<'MIN' | 'MAX' | 'STEP', number> = {
  MIN: 1,
  MAX: 3,
  STEP: 1,
};

export const NUMERIC_INPUT_LIMITS: Record<'MIN' | 'MAX' | 'STEP', number> = {
  MIN: 0,
  MAX: 1000,
  STEP: 1,
};
export const DEBOUNCE_TIME = 750;

export const SESSION_STORAGE_KEY = 'paint-calculator';

export const EMAIL_REGEX = /^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,5})+$/;
