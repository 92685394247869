import React, { Children, FC, ReactNode } from 'react';
import './disclaimer.scss';

export interface DisclaimerProps {
  children?: ReactNode | ReactNode[];
}

const Disclaimer: FC<DisclaimerProps> = ({ children }) => {
  const childrenArray = Array.isArray(children) ? children : [children];
  return (
    <div className='pc-disclaimer'>
      {Children.map(childrenArray, (child, index) => (
        <p key={index}>{child}</p>
      ))}
    </div>
  );
};

export default Disclaimer;
