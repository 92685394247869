import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import './email.scss';
import Button from '../button/button';
import Checkbox from '../checkbox/checkbox';
import TextInput from '../text-input/text-input';
import { EMAIL_REGEX } from '../../constants/constants';
import { PaintCalculatorAuthoredData } from '../../../shared/types/paint-calculator/types';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { useTranslations } from '../../hooks/use-translations';

interface EmailRequestPayloadProps {
  campaignId: string;
  ceilingAmountMessage: string;
  ceilingAreaMessage: string;
  ceilingCountMessage: string;
  emailType: 'paintcalc';
  endpoint: string;
  hostName: string;
  input: [{ email: string }];
  locale: string;
  trimAmountMessage: string;
  trimAreaMessage: string;
  trimCountMessage: string;
  wallAmountMessage: string;
  wallAreaMessage: string;
  wallCountMessage: string;
}

export interface EmailProps extends PaintCalculatorAuthoredData {
  isError?: boolean;
}

const sendEmailRequest = async ({
  endpoint,
  payload,
}: {
  endpoint: string;
  payload: EmailRequestPayloadProps;
}): Promise<void> => {
  const response = await fetch(endpoint, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(`An error occurred ${response.status} ${response.statusText}`);
  }

  return await response.json();
};

const Email: FC<EmailProps> = ({ brand, privacy, locale, campaignId, host, path, isError }) => {
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [showSentMessage, setShowSentMessage] = useState<boolean>(false);
  const [hasSent, setHasSent] = useState<boolean>(false);
  const [unifiedIsError, setUnifiedIsError] = useState<boolean>(false);
  const translations = useTranslations(brand);
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);

  const handlePrivacyCheck = () => {
    setPrivacyChecked((prev) => !prev);
  };

  const handleSubmit = useCallback(() => {
    setSubmitClicked(true);
    if (!campaignId || !path || !host || !locale || !store.email || !privacyChecked) return;
    const payload: EmailRequestPayloadProps = {
      campaignId: campaignId, // authored
      ceilingAmountMessage: `${store.space === 'interior' ? store[store.calculation ?? 'quick']?.ceiling?.coverage : 0} ${translations[store.unit === 'imperial' ? 'ImperialUnitOfVolume' : 'MetricUnitOfVolume']}`, // calculated
      ceilingAreaMessage: `${store.space === 'interior' ? store[store.calculation ?? 'quick']?.ceiling?.area : 0} ${translations[store.unit === 'imperial' ? 'ImperialUnitOfVolume' : 'MetricUnitOfVolume']}`, // calculated
      ceilingCountMessage: `${store.space === 'interior' ? (store[store.calculation ?? 'quick']?.ceiling?.coats ?? 1) : 0}`, // entered
      emailType: 'paintcalc', // fixed
      endpoint: path, // authored
      hostName: host, // authored
      input: [{ email: store.email }], // entered
      locale: locale, // authored
      trimAmountMessage: `${store.calculation === 'quick' ? 0 : store.custom?.trim?.coverage} ${translations[store.unit === 'imperial' ? 'ImperialUnitOfVolume' : 'MetricUnitOfVolume']}`, // calculated
      trimAreaMessage: `${store.calculation === 'quick' ? 0 : store.custom?.trim?.area} ${translations[store.unit === 'imperial' ? 'ImperialUnitOfVolume' : 'MetricUnitOfVolume']}`, // calculated
      trimCountMessage: `${store.calculation === 'quick' ? 0 : store.custom?.trim?.coats}`, // entered
      wallAmountMessage: `${store[store.calculation ?? 'quick']?.area?.coverage} ${translations[store.unit === 'imperial' ? 'ImperialUnitOfVolume' : 'MetricUnitOfVolume']}`, // calculated
      wallAreaMessage: `${store[store.calculation ?? 'quick']?.area?.area} ${translations[store.unit === 'imperial' ? 'ImperialUnitOfVolume' : 'MetricUnitOfVolume']}`, // calculated
      wallCountMessage: `${store[store.calculation ?? 'quick']?.area?.coats}`, // calculated
    };
    if (store.isEmailValid) {
      sendEmailRequest({ endpoint: path, payload })
        .then(() => {
          setHasSent(true);
          setShowSentMessage(true);
          setTimeout(() => setShowSentMessage(false), 5000);
        })
        .catch((error) => console.error(error));
    }
  }, [campaignId, host, locale, path, privacyChecked, store, translations]);

  const handleInputChange = useCallback(
    (id: string, value: string | number) => {
      if (!dispatch) return;
      if (typeof value !== 'string') return;
      switch (id) {
        case 'email-address':
          dispatch({ type: 'SET_EMAIL_ADDRESS', value });
          dispatch({
            type: 'SET_EMAIL_IS_VALID',
            isValid: value === '' ? true : EMAIL_REGEX.test(value),
          });
          break;
        default:
          console.warn(`handleInputChangeCustom Unknown id`, id, value);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setUnifiedIsError(!!isError || store.isEmailValid === false || false);
  }, [isError, store.isEmailValid]);

  return (
    <section className='pc-email'>
      <hr />
      <h4>{translations.EMAIL_ME_MY_RESULTS}</h4>
      <TextInput
        id='email-address'
        type='text'
        inputMode='email'
        supLabel={translations.EmailAddress}
        isError={unifiedIsError}
        callBack={(id, value) => handleInputChange(id, value)}
        value={store.email}
      />
      <div className='pc-email__message'>
        <Checkbox id='privacy' isChecked={privacyChecked} callback={handlePrivacyCheck}>
          <>
            <p>
              <span>
                {translations.Privacy}{' '}
                <Button style='link' wrapperElement='span' route={privacy}>
                  {translations.PrivacyLinkText}
                </Button>
              </span>
              {!privacyChecked && submitClicked && (
                <span className='pc-email__privacy_error'>{translations.privacyError}</span>
              )}
            </p>
          </>
        </Checkbox>
        {showSentMessage && (
          <>
            <p>{translations.Sent}</p>
            <p>{translations.patience}</p>
          </>
        )}
      </div>
      <div className='pc-email__send-button'>
        <Button style='button' callback={handleSubmit}>
          {hasSent ? translations.Resend : translations.Submit}
        </Button>
      </div>
    </section>
  );
};

export default Email;
