export const forceNumeric = (value: number | string | undefined): number => {
  return Number(value) >= 0 ? Number(value) : 0;
};

export const roundUp = (value: number | string | undefined) => {
  return Math.ceil(forceNumeric(value));
};

export const roundDown = (value: number | string | undefined) => {
  return Math.floor(forceNumeric(value));
};

export const roundMath = (value: number | string | undefined, decimals = 0) => {
  return forceNumeric(forceNumeric(value).toFixed(decimals));
};
