import React, { CSSProperties, FC, useCallback, useContext, useEffect, useState } from 'react';
import './wall.scss';
import Ceiling from '../ceiling/ceiling';
import Checkbox from '../checkbox/checkbox';
import ComponentActivator from '../component-activator/component-activator';
import Door from '../door/door';
import Opening from '../opening/opening';
import Select, { SelectProps } from '../select/select';
import TextInput from '../text-input/text-input';
import Trim from '../trim/trim';
import Window from '../window/window';
import { CONSTANTS } from '../../constants/constants';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { forceNumeric } from '../../../shared/utils/forceNumeric';
import { useTranslations } from '../../hooks/use-translations';

export interface WallProps {
  wallId: string;
  isError?: boolean;
}

const Wall: FC<WallProps> = ({ wallId, isError }) => {
  const [componentActivatorCount, setComponentActivatorCount] = useState<CSSProperties>({});
  const [textureOptions, setTextureOptions] = useState<SelectProps['options']>([]);
  const [wallCount, setWallCount] = useState(0);
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);

  const handleTitleClick = useCallback(
    (wallId: string) => {
      if (dispatch) {
        dispatch({ type: 'CLEAR_CUSTOM_WALL', wallId });
      }
    },
    [dispatch],
  );

  const handleInputChange = useCallback(
    (wallId: string, id: string, value: string | number) => {
      if (!dispatch) return;
      switch (id) {
        case 'height-big':
          dispatch({
            type: 'SET_CUSTOM_WALL_HEIGHT_BIG',
            wallId,
            value: forceNumeric(value),
          });
          break;
        case 'height-small':
          dispatch({
            type: 'SET_CUSTOM_WALL_HEIGHT_SMALL',
            wallId,
            value: forceNumeric(value),
          });
          break;
        case 'width-big':
          dispatch({
            type: 'SET_CUSTOM_WALL_WIDTH_BIG',
            wallId,
            value: forceNumeric(value),
          });
          break;
        case 'width-small':
          dispatch({
            type: 'SET_CUSTOM_WALL_WIDTH_SMALL',
            wallId,
            value: forceNumeric(value),
          });
          break;
        case 'peak-big':
          dispatch({
            type: 'SET_CUSTOM_WALL_PEAK_BIG',
            wallId,
            value: forceNumeric(value),
          });
          break;
        case 'peak-small':
          dispatch({
            type: 'SET_CUSTOM_WALL_PEAK_SMALL',
            wallId,
            value: forceNumeric(value),
          });
          break;
        case 'texture':
          dispatch({
            type: 'SET_CUSTOM_WALL_TEXTURE',
            wallId,
            value: value.toString(),
          });
          break;
        case 'baseboard-small':
          dispatch({
            type: 'SET_CUSTOM_WALL_BASEBOARD_SMALL',
            wallId,
            value: forceNumeric(value),
          });
          break;
        case 'molding-small':
          dispatch({
            type: 'SET_CUSTOM_WALL_MOLDING_SMALL',
            wallId,
            value: forceNumeric(value),
          });
          break;
        default:
          console.warn(`handleInputChange Unknown id`, wallId, id, value);
      }
    },
    [dispatch],
  );

  const handleHasPeak = useCallback(
    (id: string) => {
      if (dispatch) {
        dispatch({ type: 'SET_CUSTOM_WALL_HAS_PEAK', wallId: id });
      }
    },
    [dispatch],
  );

  const handleComponentActivatorCallback = useCallback(
    (wallId: string, id: string) => {
      if (!dispatch) return;
      switch (id) {
        case 'ceiling':
          dispatch({ type: 'ADD_CUSTOM_CEILING', wallId });
          break;
        case 'door':
          dispatch({ type: 'ADD_CUSTOM_DOOR', wallId });
          break;
        case 'opening':
          dispatch({ type: 'ADD_CUSTOM_OPENING', wallId });
          break;
        case 'trim':
          dispatch({ type: 'ADD_CUSTOM_TRIM', wallId });
          break;
        case 'window':
          dispatch({ type: 'ADD_CUSTOM_WINDOW', wallId });
          break;
        default:
          console.warn(`handleComponentActivatorCallback Unknown id`, id);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setComponentActivatorCount({
      '--pc-component-activator-count': store.space === 'interior' ? 5 : 4,
    } as CSSProperties);
  }, [store.space]);

  useEffect(() => {
    const unit = store.unit === 'imperial' ? 'IMPERIAL' : 'METRIC';
    setTextureOptions([
      {
        label: translations.Fine,
        value: (CONSTANTS[unit]?.TEXTURE.FINE ?? 1).toString(),
      },
      {
        label: translations.Medium,
        value: (CONSTANTS[unit]?.TEXTURE.MEDIUM ?? 1).toString(),
      },
      {
        label: translations.ExtraCoarse,
        value: (CONSTANTS[unit]?.TEXTURE.EXTRA_COARSE ?? 1).toString(),
      },
    ]);
  }, [store.unit, translations.ExtraCoarse, translations.Fine, translations.Medium]);

  useEffect(() => {
    setWallCount(Object.keys(store.custom?.wall ?? {}).length);
  }, [store]);

  return (
    <div className={`pc-wall pc-wall--${wallId}`}>
      <section className='pc-wall__title'>
        {wallCount > 1 ? (
          <h4 role='button' onClick={() => handleTitleClick(wallId)} tabIndex={0}>
            <span>{store.space === 'interior' ? translations.WALL : translations.SIDE}</span>
            <FaMinusCircle />
          </h4>
        ) : (
          <h4>
            <span>{store.space === 'interior' ? translations.WALL : translations.SIDE}</span>
          </h4>
        )}
      </section>
      <section className='pc-wall__dimentions'>
        <TextInput
          id='height-big'
          type='text'
          inputMode='numeric'
          supLabel={translations.Height}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={isError}
          value={store.custom?.wall?.[wallId]?.height?.big}
          callBack={(id, value) => handleInputChange(wallId, id, value)}
        />
        <TextInput
          id='height-small'
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={isError}
          value={store.custom?.wall?.[wallId]?.height?.small}
          callBack={(id, value) => handleInputChange(wallId, id, value)}
        />
        <TextInput
          id='width-big'
          type='text'
          inputMode='numeric'
          supLabel={translations.Length}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={isError}
          value={store.custom?.wall?.[wallId]?.width?.big}
          callBack={(id, value) => handleInputChange(wallId, id, value)}
        />
        <TextInput
          id='width-small'
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={isError}
          value={store.custom?.wall?.[wallId]?.width?.small}
          callBack={(id, value) => handleInputChange(wallId, id, value)}
        />
      </section>
      {isError && (
        <section className='pc-wall__error'>
          <FormattedMessage defaultMessage='The total wall area is less than total area of windows, doors, openings, and trim. Please recheck your measurements and try again.' />
        </section>
      )}
      <section className='pc-wall__peak'>
        <Checkbox
          id='has-peak'
          isChecked={!!store.custom?.wall?.[wallId]?.hasPeak}
          callback={() => handleHasPeak(wallId)}
        >
          <FormattedMessage defaultMessage='Does this wall have a peak?' />
        </Checkbox>
        {!!store.custom?.wall?.[wallId]?.hasPeak && (
          <>
            <TextInput
              id='peak-big'
              type='text'
              inputMode='numeric'
              supLabel={translations.PeakHeight}
              subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
              callBack={(id, value) => handleInputChange(wallId, id, value)}
              value={store.custom?.wall?.[wallId]?.peak?.big}
            />
            <TextInput
              id='peak-small'
              type='text'
              inputMode='numeric'
              supLabel={<>&nbsp;</>}
              subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
              callBack={(id, value) => handleInputChange(wallId, id, value)}
              value={store.custom?.wall?.[wallId]?.peak?.small}
            />
          </>
        )}
      </section>
      <section className='pc-wall__texture'>
        <Select
          supLabel={translations.Texture}
          options={textureOptions}
          id='texture'
          value={store.custom?.wall?.[wallId]?.texture ?? textureOptions?.[0]?.value}
          callBack={(id, value) => handleInputChange(wallId, id, value)}
        />
      </section>
      <section className='pc-wall__block-elements'>
        <TextInput
          id='baseboard-small'
          type='text'
          inputMode='numeric'
          supLabel={translations.Baseboard}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          callBack={(id, value) => handleInputChange(wallId, id, value)}
          value={store.custom?.wall?.[wallId]?.baseboard?.small}
        />
        <TextInput
          id='molding-small'
          type='text'
          inputMode='numeric'
          supLabel={translations.CrownMolding}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          callBack={(id, value) => handleInputChange(wallId, id, value)}
          value={store.custom?.wall?.[wallId]?.molding?.small}
        />
      </section>
      {Object.entries(store.custom?.wall?.[wallId]?.door ?? {}).map(([doorId]) => (
        <Door wallId={wallId} doorId={doorId} key={doorId} />
      ))}
      {Object.entries(store.custom?.wall?.[wallId]?.window ?? {}).map(([windowId]) => (
        <Window wallId={wallId} windowId={windowId} key={windowId} />
      ))}
      {Object.entries(store.custom?.wall?.[wallId]?.opening ?? {}).map(([openingId]) => (
        <Opening wallId={wallId} id={openingId} key={openingId} />
      ))}
      {Object.entries(store.custom?.wall?.[wallId]?.trim ?? {}).map(([trimId]) => (
        <Trim wallId={wallId} id={trimId} key={trimId} />
      ))}
      {store.space === 'interior' && (
        <>
          {Object.entries(store.custom?.wall?.[wallId]?.ceiling ?? {}).map(([ceilingId]) => (
            <Ceiling wallId={wallId} id={ceilingId} key={ceilingId} />
          ))}
        </>
      )}
      <section className='pc-wall__component-activators' style={componentActivatorCount}>
        <ComponentActivator
          id='door'
          text={translations.Door}
          callback={() => handleComponentActivatorCallback(wallId, 'door')}
          tooltipText={translations.doorTooltip}
        >
          <FaPlusCircle />
        </ComponentActivator>
        <ComponentActivator
          id='window'
          text={translations.Window}
          callback={() => handleComponentActivatorCallback(wallId, 'window')}
          tooltipText={translations.windowTooltip}
        >
          <FaPlusCircle />
        </ComponentActivator>
        <ComponentActivator
          id='opening'
          text={translations.Opening}
          callback={() => handleComponentActivatorCallback(wallId, 'opening')}
          tooltipText={translations.openingTooltip}
        >
          <FaPlusCircle />
        </ComponentActivator>
        <ComponentActivator
          id='trim'
          text={translations.Trim}
          callback={() => handleComponentActivatorCallback(wallId, 'trim')}
          tooltipText={translations.trimTooltip}
        >
          <FaPlusCircle />
        </ComponentActivator>
        {store.space === 'interior' && (
          <ComponentActivator
            id='ceiling'
            text={translations.Ceiling}
            callback={() => handleComponentActivatorCallback(wallId, 'ceiling')}
            tooltipText={translations.ceilingTooltip}
          >
            <FaPlusCircle />
          </ComponentActivator>
        )}
      </section>
    </div>
  );
};

export default Wall;
