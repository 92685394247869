import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import './trim.scss';
import TextInput from '../text-input/text-input';
import { FaMinusCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { forceNumeric } from '../../../shared/utils/forceNumeric';
import { useTranslations } from '../../hooks/use-translations';

export interface TrimProps {
  id: string;
  isError?: boolean;
  wallId: string;
}

const Trim: FC<TrimProps> = ({ id: trimId, isError, wallId }) => {
  const [unifiedIsError, setUnifiedIsError] = useState<boolean>(false);
  const [localIsError, setLocalIsError] = useState<boolean>(false);
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);

  const handleTitleClick = useCallback(
    (trimId: string) => {
      if (dispatch) {
        dispatch({ type: 'CLEAR_CUSTOM_TRIM', wallId, trimId });
      }
    },
    [dispatch, wallId],
  );

  const handleInputChange = useCallback(
    (trimId: string, id: string, value: string | number) => {
      if (!dispatch) return;
      value = forceNumeric(value);
      switch (id) {
        case `${trimId}-height-big`:
          dispatch({ type: 'SET_CUSTOM_TRIM_HEIGHT_BIG', wallId, trimId, value });
          break;
        case `${trimId}-height-small`:
          dispatch({ type: 'SET_CUSTOM_TRIM_HEIGHT_SMALL', wallId, trimId, value });
          break;
        case `${trimId}-width-big`:
          dispatch({ type: 'SET_CUSTOM_TRIM_WIDTH_BIG', wallId, trimId, value });
          break;
        case `${trimId}-width-small`:
          dispatch({ type: 'SET_CUSTOM_TRIM_WIDTH_SMALL', wallId, trimId, value });
          break;
        default:
          console.warn(`handleInputChange Unknown id`, trimId, id, value);
      }
    },
    [dispatch, wallId],
  );

  useEffect(() => {
    setLocalIsError(!!store.custom?.wall?.[wallId].trim?.[trimId].isError || false);
    setUnifiedIsError(!!isError || !!store.custom?.wall?.[wallId].trim?.[trimId].isError || false);
  }, [isError, store.custom?.wall, wallId, trimId]);

  return (
    <div className={`pc-trim pc-trim--${trimId}`}>
      <section className='pc-trim__title'>
        <h4 role='button' onClick={() => handleTitleClick(trimId)} tabIndex={0}>
          <span>{translations.TRIM}</span>
          <FaMinusCircle />
        </h4>
      </section>
      <section className='pc-trim__dimentions'>
        <TextInput
          id={`${trimId}-height-big`}
          type='text'
          inputMode='numeric'
          supLabel={translations.Length}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.trim?.[trimId]?.height?.big}
          callBack={(id, value) => handleInputChange(trimId, id, value)}
        />
        <TextInput
          id={`${trimId}-height-small`}
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.trim?.[trimId]?.height?.small}
          callBack={(id, value) => handleInputChange(trimId, id, value)}
        />
        <TextInput
          id={`${trimId}-width-big`}
          type='text'
          inputMode='numeric'
          supLabel={translations.Width}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.trim?.[trimId]?.width?.big}
          callBack={(id, value) => handleInputChange(trimId, id, value)}
        />
        <TextInput
          id={`${trimId}-width-small`}
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId]?.trim?.[trimId]?.width?.small}
          callBack={(id, value) => handleInputChange(trimId, id, value)}
        />
      </section>
      {localIsError && (
        <section className='pc-trim__error'>
          <FormattedMessage defaultMessage='The total trim area exceeds your total wall area. Please recheck your measurements and try again.' />
        </section>
      )}
    </div>
  );
};

export default Trim;
