import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import './ceiling.scss';
import TextInput from '../text-input/text-input';
import { FaMinusCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { forceNumeric } from '../../../shared/utils/forceNumeric';
import { useTranslations } from '../../hooks/use-translations';

export interface CeilingProps {
  id: string;
  isError?: boolean;
  wallId: string;
}

const Ceiling: FC<CeilingProps> = ({ id: ceilingId, isError, wallId }) => {
  const [unifiedIsError, setUnifiedIsError] = useState<boolean>(false);
  const [localIsError, setLocalIsError] = useState<boolean>(false);
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);

  const handleTitleClick = useCallback(
    (ceilingId: string) => {
      if (dispatch) {
        dispatch({ type: 'CLEAR_CUSTOM_CEILING', wallId, ceilingId });
      }
    },
    [dispatch, wallId],
  );

  const handleInputChange = useCallback(
    (wallId: string, ceilingId: string, id: string, value: string | number) => {
      if (!dispatch) return;
      value = forceNumeric(value);
      switch (id) {
        case `${ceilingId}-height-big`:
          dispatch({
            type: 'SET_CUSTOM_CEILING_HEIGHT_BIG',
            wallId,
            ceilingId,
            value,
          });
          break;
        case `${ceilingId}-height-small`:
          dispatch({
            type: 'SET_CUSTOM_CEILING_HEIGHT_SMALL',
            wallId,
            ceilingId,
            value,
          });
          break;
        case `${ceilingId}-width-big`:
          dispatch({
            type: 'SET_CUSTOM_CEILING_WIDTH_BIG',
            wallId,
            ceilingId,
            value,
          });
          break;
        case `${ceilingId}-width-small`:
          dispatch({
            type: 'SET_CUSTOM_CEILING_WIDTH_SMALL',
            wallId,
            ceilingId,
            value,
          });
          break;
        default:
          console.warn(`handleInputChange Unknown id`, ceilingId, id, value);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setLocalIsError(!!store.custom?.wall?.[wallId].ceiling?.[ceilingId].isError || false);
    setUnifiedIsError(!!isError || !!store.custom?.wall?.[wallId].ceiling?.[ceilingId].isError || false);
  }, [ceilingId, isError, store.custom?.wall, wallId]);

  return (
    <div className={`pc-ceiling pc-ceiling--${ceilingId}`}>
      <section className='pc-ceiling__title'>
        <h4 role='button' onClick={() => handleTitleClick(ceilingId)} tabIndex={0}>
          <span>{translations.CEILING}</span>
          <FaMinusCircle />
        </h4>
      </section>
      <section className='pc-ceiling__dimentions'>
        <TextInput
          id={`${ceilingId}-height-big`}
          type='text'
          inputMode='numeric'
          supLabel={translations.Length}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId].ceiling?.[ceilingId]?.height?.big}
          callBack={(id, value) => handleInputChange(wallId, ceilingId, id, value)}
        />
        <TextInput
          id={`${ceilingId}-height-small`}
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId].ceiling?.[ceilingId]?.height?.small}
          callBack={(id, value) => handleInputChange(wallId, ceilingId, id, value)}
        />
        <TextInput
          id={`${ceilingId}-width-big`}
          type='text'
          inputMode='numeric'
          supLabel={translations.Width}
          subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId].ceiling?.[ceilingId]?.width?.big}
          callBack={(id, value) => handleInputChange(wallId, ceilingId, id, value)}
        />
        <TextInput
          id={`${ceilingId}-width-small`}
          type='text'
          inputMode='numeric'
          supLabel={<>&nbsp;</>}
          subLabel={store.unit === 'imperial' ? translations.Inches : translations.Centimeters}
          isError={unifiedIsError}
          value={store.custom?.wall?.[wallId].ceiling?.[ceilingId]?.width?.small}
          callBack={(id, value) => handleInputChange(wallId, ceilingId, id, value)}
        />
      </section>
      {localIsError && (
        <section className='pc-ceiling__error'>
          <FormattedMessage defaultMessage='The total ceiling area exceeds your total wall area. Please recheck your measurements and try again.' />
        </section>
      )}
    </div>
  );
};

export default Ceiling;
