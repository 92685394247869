import React, { FC, KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import './component-activator.scss';
import { Tooltip } from 'react-tooltip';

export interface ComponentActivatorProps {
  id: string;
  text: string | ReactNode;
  tooltipText?: string | ReactNode;
  children?: ReactNode;
  callback: () => void;
}

const ComponentActivator: FC<ComponentActivatorProps> = ({ id, text, tooltipText, children, callback }) => {
  const [hasHover, setHasHover] = useState<boolean | null>(null);
  const handleCallback = () => {
    if (callback) callback();
  };

  const handleKeyup = (e: KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleCallback();
    }
  };

  useEffect(() => {
    const hasHoverQuery = window.matchMedia('(hover: hover) and (width > 50rem)');
    setHasHover(hasHoverQuery.matches);
    const hasHoverOnChange = (e: MediaQueryListEvent) => {
      setHasHover(e.matches);
    };
    hasHoverQuery.addEventListener('change', hasHoverOnChange);
    return () => hasHoverQuery.removeEventListener('change', hasHoverOnChange);
  }, []);

  return (
    <>
      <div
        className={`pc-component-activator pc-component-activator--${id}`}
        onClick={handleCallback}
        onKeyUp={handleKeyup}
        role='button'
        tabIndex={0}
      >
        {children}
        <span>{text}</span>
      </div>
      {hasHover && (
        <Tooltip className='pc-component-activator-tooltip' anchorSelect={`.pc-component-activator--${id}`}>
          {tooltipText}
        </Tooltip>
      )}
    </>
  );
};

export default ComponentActivator;
