import React, { createContext, FC, ReactNode, useEffect, useReducer } from 'react';
import { CALCULATION, PAINT_TYPE, SPACE, UNITS_OF_MEASUREMENT } from '../../shared/types/paint-calculator/types';
import { SESSION_STORAGE_KEY } from '../constants/constants';
import { customArea, customCoverage } from '../calculation/custom';

export interface CeilingData {
  height?: { big: number; small: number };
  width?: { big: number; small: number };
  isError?: boolean;
}

export interface TrimData {
  height?: { big: number; small: number };
  width?: { big: number; small: number };
  isError?: boolean;
}

export interface OpeningData {
  height?: { big: number; small: number };
  width?: { big: number; small: number };
  isError?: boolean;
}

export interface WindowData {
  height?: { big: number; small: number };
  width?: { big: number; small: number };
  hasTrim?: boolean;
  trim?: { small: number };
  isError?: boolean;
}

export interface DoorData {
  height?: { big: number; small: number };
  width?: { big: number; small: number };
  hasTrim?: boolean;
  trim?: { small: number };
  isError?: boolean;
}

export interface WallData {
  height?: { big: number; small: number };
  width?: { big: number; small: number };
  hasPeak?: boolean;
  peak?: { big: number; small: number };
  texture?: string;
  baseboard?: { small: number };
  molding?: { small: number };
  ceiling?: Record<string, CeilingData>;
  door?: Record<string, DoorData>;
  opening?: Record<string, OpeningData>;
  trim?: Record<string, TrimData>;
  window?: Record<string, WindowData>;
  isError?: boolean;
}

export interface CustomFormStore {
  wall: Record<string, WallData>;
  area?: {
    area?: number;
    areaWithTexture?: number;
    coverage?: number;
    coats?: number;
  };
  ceiling?: { area?: number; coverage?: number; coats?: number };
  trim?: { area?: number; coverage?: number; coats?: number };
  isError?: boolean;
}

export interface QuickFormStore {
  height?: number;
  width?: number;
  doors?: number;
  windows?: number;
  area?: { area?: number; coverage?: number; coats?: number };
  ceiling?: { area?: number; coverage?: number; coats?: number };
  isError?: boolean;
}

export interface PaintCalculatorStore {
  sessionStorageChecked?: boolean;
  email?: string;
  isEmailValid?: boolean;
  unit?: UNITS_OF_MEASUREMENT;
  paintType?: PAINT_TYPE;
  space?: SPACE;
  calculation?: CALCULATION;
  quick?: QuickFormStore;
  custom?: CustomFormStore;
}

export const PaintCalculatorState = createContext<PaintCalculatorStore>({});
export const PaintCalculatorDispatch = createContext<React.Dispatch<Action> | null>(null);

export type Action =
  | { type: 'INITIALIZE'; newState: PaintCalculatorStore }
  | { type: 'CLEAR_ALL' }
  | { type: 'SET_EMAIL_ADDRESS'; value: string }
  | { type: 'SET_EMAIL_IS_VALID'; isValid: boolean }
  | { type: 'SET_UNITS_OF_MEASUREMENT'; unit: UNITS_OF_MEASUREMENT }
  | { type: 'SET_PAINT_TYPE'; paintType: PAINT_TYPE }
  | { type: 'SET_SPACE'; space: SPACE }
  | { type: 'SET_CALCULATION'; calculation: CALCULATION }
  | { type: 'SET_QUICK_HEIGHT'; height: number }
  | { type: 'SET_QUICK_WIDTH'; width: number }
  | { type: 'SET_QUICK_DOORS'; doors: number }
  | { type: 'SET_QUICK_WINDOWS'; windows: number }
  | { type: 'SET_QUICK_AREA'; area: number }
  | { type: 'SET_QUICK_COVERAGE'; coverage: number }
  | { type: 'SET_QUICK_CEILING_AREA'; area: number }
  | { type: 'SET_QUICK_CEILING_COVERAGE'; coverage: number }
  | { type: 'SET_QUICK_AREA_COATS'; coats: number }
  | { type: 'SET_QUICK_CEILING_COATS'; coats: number }
  | { type: 'SET_QUICK_IS_ERROR'; isError: boolean }
  | { type: 'CLEAR_QUICK' }
  | { type: 'CLEAR_CUSTOM_WALL'; wallId: string }
  | { type: 'SET_CUSTOM_WALL_HEIGHT_BIG'; wallId: string; value: number }
  | { type: 'SET_CUSTOM_WALL_HEIGHT_SMALL'; wallId: string; value: number }
  | { type: 'SET_CUSTOM_WALL_WIDTH_BIG'; wallId: string; value: number }
  | { type: 'SET_CUSTOM_WALL_WIDTH_SMALL'; wallId: string; value: number }
  | { type: 'SET_CUSTOM_WALL_HAS_PEAK'; wallId: string }
  | { type: 'SET_CUSTOM_WALL_PEAK_BIG'; wallId: string; value: number }
  | { type: 'SET_CUSTOM_WALL_PEAK_SMALL'; wallId: string; value: number }
  | { type: 'SET_CUSTOM_WALL_TEXTURE'; wallId: string; value: string }
  | { type: 'SET_CUSTOM_WALL_BASEBOARD_SMALL'; wallId: string; value: number }
  | { type: 'SET_CUSTOM_WALL_MOLDING_SMALL'; wallId: string; value: number }
  | { type: 'SET_CUSTOM_WALL_IS_ERROR'; wallId: string; isError: boolean }
  | { type: 'CLEAR_CUSTOM_CEILING'; wallId: string; ceilingId: string }
  | { type: 'ADD_CUSTOM_CEILING'; wallId: string; ceilingId?: string }
  | {
      type: 'SET_CUSTOM_CEILING_HEIGHT_BIG';
      wallId: string;
      ceilingId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_CEILING_HEIGHT_SMALL';
      wallId: string;
      ceilingId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_CEILING_WIDTH_BIG';
      wallId: string;
      ceilingId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_CEILING_WIDTH_SMALL';
      wallId: string;
      ceilingId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_CEILING_IS_ERROR';
      wallId: string;
      ceilingId: string;
      isError: boolean;
    }
  | { type: 'CLEAR_CUSTOM_OPENING'; wallId: string; openingId: string }
  | { type: 'ADD_CUSTOM_OPENING'; wallId: string; openingId?: string }
  | {
      type: 'SET_CUSTOM_OPENING_HEIGHT_BIG';
      wallId: string;
      openingId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_OPENING_HEIGHT_SMALL';
      wallId: string;
      openingId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_OPENING_WIDTH_BIG';
      wallId: string;
      openingId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_OPENING_WIDTH_SMALL';
      wallId: string;
      openingId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_OPENING_IS_ERROR';
      wallId: string;
      openingId: string;
      isError: boolean;
    }
  | { type: 'CLEAR_CUSTOM_TRIM'; wallId: string; trimId: string }
  | { type: 'ADD_CUSTOM_TRIM'; wallId: string }
  | {
      type: 'SET_CUSTOM_TRIM_HEIGHT_BIG';
      wallId: string;
      trimId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_TRIM_HEIGHT_SMALL';
      wallId: string;
      trimId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_TRIM_WIDTH_BIG';
      wallId: string;
      trimId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_TRIM_WIDTH_SMALL';
      wallId: string;
      trimId: string;
      value: number;
    }
  | { type: 'SET_CUSTOM_WALL_MOLDING_SMALL'; wallId: string; value: number }
  | { type: 'ADD_CUSTOM_DOOR'; wallId: string; doorId?: string }
  | { type: 'CLEAR_CUSTOM_DOOR'; wallId: string; doorId: string }
  | {
      type: 'SET_CUSTOM_DOOR_HEIGHT_BIG';
      wallId: string;
      doorId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_DOOR_HEIGHT_SMALL';
      wallId: string;
      doorId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_DOOR_WIDTH_BIG';
      wallId: string;
      doorId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_DOOR_WIDTH_SMALL';
      wallId: string;
      doorId: string;
      value: number;
    }
  | { type: 'SET_CUSTOM_DOOR_HAS_TRIM'; wallId: string; doorId: string }
  | {
      type: 'SET_CUSTOM_DOOR_TRIM_SMALL';
      wallId: string;
      doorId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_DOOR_IS_ERROR';
      wallId: string;
      doorId: string;
      isError: boolean;
    }
  | {
      type: 'SET_CUSTOM_WINDOW_IS_ERROR';
      wallId: string;
      windowId: string;
      isError: boolean;
    }
  | {
      type: 'SET_CUSTOM_OPENING_IS_ERROR';
      wallId: string;
      openingId: string;
      isError: boolean;
    }
  | {
      type: 'SET_CUSTOM_TRIM_IS_ERROR';
      wallId: string;
      trimId: string;
      isError: boolean;
    }
  | { type: 'SET_CUSTOM_OVERALL_AREA'; wallArea: number }
  | {
      type: 'SET_CUSTOM_OVERALL_AREA_WITH_TEXTURE';
      wallAreaWithTexture: number;
    }
  | { type: 'SET_CUSTOM_OVERALL_TRIM'; trimArea: number }
  | { type: 'SET_CUSTOM_OVERALL_CEILING'; ceilingArea: number }
  | { type: 'SET_CUSTOM_OVERALL_AREA_COVERAGE'; coverage: number }
  | { type: 'SET_CUSTOM_OVERALL_TRIM_COVERAGE'; coverage: number }
  | { type: 'SET_CUSTOM_OVERALL_CEILING_COVERAGE'; coverage: number }
  | { type: 'SET_CUSTOM_AREA_COATS'; coats: number }
  | { type: 'SET_CUSTOM_TRIM_COATS'; coats: number }
  | { type: 'SET_CUSTOM_CEILING_COATS'; coats: number }
  | { type: 'ADD_CUSTOM_WINDOW'; wallId: string }
  | { type: 'ADD_CUSTOM_WALL'; wallId?: string }
  | { type: 'CLEAR_CUSTOM_WINDOW'; wallId: string; windowId: string }
  | {
      type: 'SET_CUSTOM_WINDOW_HEIGHT_BIG';
      wallId: string;
      windowId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_WINDOW_HEIGHT_SMALL';
      wallId: string;
      windowId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_WINDOW_WIDTH_BIG';
      wallId: string;
      windowId: string;
      value: number;
    }
  | {
      type: 'SET_CUSTOM_WINDOW_WIDTH_SMALL';
      wallId: string;
      windowId: string;
      value: number;
    }
  | { type: 'SET_CUSTOM_WINDOW_HAS_TRIM'; wallId: string; windowId: string }
  | {
      type: 'SET_CUSTOM_WINDOW_TRIM_SMALL';
      wallId: string;
      windowId: string;
      value: number;
    }
  | { type: 'RESET_FORM' };

interface PaintCalculatorProviderProps {
  children: ReactNode;
}

const setCustomValueWall = (args: {
  state: PaintCalculatorStore;
  type: 'wall';
  wallId: string;
  key?: 'hasPeak' | 'texture' | 'hasTrim' | 'isError';
  inner?: string;
  outer?: 'height' | 'width' | 'peak' | 'baseboard' | 'molding';
  value: string | number | boolean | { big?: string; small?: string };
}): PaintCalculatorStore => {
  const { state, wallId, key, outer, inner, value } = args;
  if (key) {
    return {
      ...state,
      custom: {
        ...state.custom,
        wall: {
          ...state.custom?.wall,
          [wallId]: {
            ...state.custom?.wall?.[wallId],
            [key]: value,
          },
        },
      },
    };
  }
  if (inner && outer) {
    return {
      ...state,
      custom: {
        ...state.custom,
        wall: {
          ...state.custom?.wall,
          [wallId]: {
            ...state.custom?.wall?.[wallId],
            [outer]: {
              ...state.custom?.wall?.[wallId]?.[outer],
              [inner]: value,
            },
          },
        },
      },
    };
  }
  return state;
};

const setCustomValueWithTrim = (args: {
  state: PaintCalculatorStore;
  type: 'door' | 'window';
  wallId: string;
  elementId?: string;
  key?: 'hasPeak' | 'texture' | 'hasTrim' | 'isError';
  inner?: string;
  outer?: 'height' | 'width' | 'trim';
  value: number | boolean | { big?: string; small?: string };
}): PaintCalculatorStore => {
  const { state, type, wallId, elementId, key, outer, inner, value } = args;
  if (!elementId) return state;
  if (key) {
    return {
      ...state,
      custom: {
        ...state.custom,
        wall: {
          ...state.custom?.wall,
          [wallId]: {
            ...state.custom?.wall?.[wallId],
            [type]: {
              ...state.custom?.wall?.[wallId]?.[type],
              [elementId]: {
                ...state.custom?.wall?.[wallId]?.[type]?.[elementId],
                [key]: value,
              },
            },
          },
        },
      },
    };
  }
  if (inner && outer) {
    return {
      ...state,
      custom: {
        ...state.custom,
        wall: {
          ...state.custom?.wall,
          [wallId]: {
            ...state.custom?.wall?.[wallId],
            [type]: {
              ...state.custom?.wall?.[wallId]?.[type],
              [elementId]: {
                ...state.custom?.wall?.[wallId]?.[type]?.[elementId],
                [outer]: {
                  ...state.custom?.wall?.[wallId]?.[type]?.[elementId]?.[outer],
                  [inner]: value,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

const setCustomValueWithOutTrim = (args: {
  state: PaintCalculatorStore;
  type: 'ceiling' | 'opening' | 'trim';
  wallId: string;
  elementId?: string;
  key?: 'hasPeak' | 'texture' | 'hasTrim' | 'isError';
  inner?: string;
  outer?: 'height' | 'width';
  value: number | boolean | { big?: string; small?: string };
}): PaintCalculatorStore => {
  const { state, type, wallId, elementId, key, outer, inner, value } = args;
  if (!elementId) return state;
  if (key) {
    return {
      ...state,
      custom: {
        ...state.custom,
        wall: {
          ...state.custom?.wall,
          [wallId]: {
            ...state.custom?.wall?.[wallId],
            [type]: {
              ...state.custom?.wall?.[wallId]?.[type],
              [elementId]: {
                ...state.custom?.wall?.[wallId]?.[type]?.[elementId],
                [key]: value,
              },
            },
          },
        },
      },
    };
  }
  if (inner && outer) {
    return {
      ...state,
      custom: {
        ...state.custom,
        wall: {
          ...state.custom?.wall,
          [wallId]: {
            ...state.custom?.wall?.[wallId],
            [type]: {
              ...state.custom?.wall?.[wallId]?.[type],
              [elementId]: {
                ...state.custom?.wall?.[wallId]?.[type]?.[elementId],
                [outer]: {
                  ...state.custom?.wall?.[wallId]?.[type]?.[elementId]?.[outer],
                  [inner]: value,
                },
              },
            },
          },
        },
      },
    };
  }
  return state;
};

export const PaintCalculatorProvider: FC<PaintCalculatorProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<React.Reducer<PaintCalculatorStore, Action>>(paintCalculatorReducer, {});

  function paintCalculatorReducer(state: PaintCalculatorStore, action: Action) {
    if (!state.sessionStorageChecked && action.type !== 'INITIALIZE') {
      return state;
    }
    switch (action.type) {
      case 'INITIALIZE':
        return action.newState;
      case 'CLEAR_ALL':
        return { sessionStorageChecked: state.sessionStorageChecked };
      case 'SET_EMAIL_ADDRESS':
        if (state.email === action.value) return state;
        return { ...state, email: action.value };
      case 'SET_EMAIL_IS_VALID':
        if (state.isEmailValid === action.isValid) return state;
        return { ...state, isEmailValid: action.isValid };
      case 'SET_UNITS_OF_MEASUREMENT':
        return { ...state, unit: action.unit };
      case 'SET_PAINT_TYPE':
        return { ...state, paintType: action.paintType };
      case 'SET_SPACE':
        return { ...state, space: action.space };
      case 'SET_CALCULATION':
        return { ...state, calculation: action.calculation };
      case 'SET_QUICK_HEIGHT':
        if (state.quick?.height === action.height) return state;
        return { ...state, quick: { ...state.quick, height: action.height } };
      case 'SET_QUICK_WIDTH':
        if (state.quick?.width === action.width) return state;
        return { ...state, quick: { ...state.quick, width: action.width } };
      case 'SET_QUICK_DOORS':
        if (state.quick?.doors === action.doors) return state;
        return { ...state, quick: { ...state.quick, doors: action.doors } };
      case 'SET_QUICK_WINDOWS':
        if (state.quick?.windows === action.windows) return state;
        return { ...state, quick: { ...state.quick, windows: action.windows } };
      case 'SET_QUICK_AREA':
        if (state.quick?.area?.area === action.area) return state;
        return {
          ...state,
          quick: {
            ...state.quick,
            area: { ...state.quick?.area, area: action.area },
          },
        };
      case 'SET_QUICK_COVERAGE':
        if (state.quick?.area?.coverage === action.coverage) return state;
        return {
          ...state,
          quick: {
            ...state.quick,
            area: { ...state.quick?.area, coverage: action.coverage },
          },
        };
      case 'SET_QUICK_CEILING_AREA':
        if (state.quick?.ceiling?.area === action.area) return state;
        return {
          ...state,
          quick: {
            ...state.quick,
            ceiling: { ...state.quick?.ceiling, area: action.area },
          },
        };
      case 'SET_QUICK_CEILING_COVERAGE':
        if (state.quick?.ceiling?.coverage === action.coverage) return state;
        return {
          ...state,
          quick: {
            ...state.quick,
            ceiling: { ...state.quick?.ceiling, coverage: action.coverage },
          },
        };
      case 'SET_QUICK_IS_ERROR':
        if (state.quick?.isError === action.isError) return state;
        return { ...state, quick: { ...state.quick, isError: action.isError } };
      case 'SET_QUICK_AREA_COATS':
        if (state.quick?.area?.coats === action.coats) return state;
        return {
          ...state,
          quick: {
            ...state.quick,
            area: { ...state.quick?.area, coats: action.coats },
          },
        };
      case 'SET_QUICK_CEILING_COATS':
        if (state.quick?.ceiling?.coats === action.coats) return state;
        return {
          ...state,
          quick: {
            ...state.quick,
            ceiling: { ...state.quick?.ceiling, coats: action.coats },
          },
        };
      case 'CLEAR_QUICK':
        return { ...state, quick: {} };
      case 'RESET_FORM':
        return { ...state, custom: { wall: {} } };
      case 'CLEAR_CUSTOM_WALL':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: Object.fromEntries(
              Object.entries(state.custom?.wall ?? {}).filter(([wallId]) => wallId !== action.wallId),
            ),
          },
        };
      case 'ADD_CUSTOM_WALL':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId ?? crypto.randomUUID()]: {},
            },
          },
        };
      case 'SET_CUSTOM_WALL_HEIGHT_BIG':
        if (state.custom?.wall?.[action.wallId]?.height?.big === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          outer: 'height',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_WALL_HEIGHT_SMALL':
        if (state.custom?.wall?.[action.wallId]?.height?.small === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          outer: 'height',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_WALL_WIDTH_BIG':
        if (state.custom?.wall?.[action.wallId]?.width?.big === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          outer: 'width',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_WALL_WIDTH_SMALL':
        if (state.custom?.wall?.[action.wallId]?.width?.small === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          outer: 'width',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_WALL_TEXTURE':
        if (state.custom?.wall?.[action.wallId]?.texture === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          key: 'texture',
          value: action.value,
        });
      case 'SET_CUSTOM_WALL_BASEBOARD_SMALL':
        if (state.custom?.wall?.[action.wallId]?.baseboard?.small === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          outer: 'baseboard',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_WALL_MOLDING_SMALL':
        if (state.custom?.wall?.[action.wallId]?.molding?.small === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          outer: 'molding',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_WALL_IS_ERROR':
        if (state.custom?.wall?.[action.wallId]?.isError === action.isError) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          key: 'isError',
          value: action.isError,
        });
      case 'SET_CUSTOM_WALL_HAS_PEAK':
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          key: 'hasPeak',
          value: !state.custom?.wall?.[action.wallId]?.hasPeak,
        });
      case 'SET_CUSTOM_WALL_PEAK_BIG':
        if (state.custom?.wall?.[action.wallId]?.peak?.big === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          outer: 'peak',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_WALL_PEAK_SMALL':
        if (state.custom?.wall?.[action.wallId]?.peak?.small === action.value) return state;
        return setCustomValueWall({
          state,
          type: 'wall',
          wallId: action.wallId,
          outer: 'peak',
          inner: 'small',
          value: action.value,
        });
      case 'CLEAR_CUSTOM_CEILING':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                ceiling: Object.fromEntries(
                  Object.entries(state.custom?.wall?.[action.wallId]?.ceiling ?? {}).filter(
                    ([ceilingId]) => ceilingId !== action.ceilingId,
                  ),
                ),
              },
            },
          },
        };
      case 'ADD_CUSTOM_CEILING':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                ceiling: {
                  ...state.custom?.wall?.[action.wallId].ceiling,
                  [action.ceilingId ?? crypto.randomUUID()]: {},
                },
              },
            },
          },
        };
      case 'SET_CUSTOM_CEILING_HEIGHT_BIG':
        if (state.custom?.wall?.[action.wallId]?.ceiling?.[action.ceilingId]?.height?.big === action.value)
          return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'ceiling',
          wallId: action.wallId,
          elementId: action.ceilingId,
          outer: 'height',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_CEILING_HEIGHT_SMALL':
        if (state.custom?.wall?.[action.wallId]?.ceiling?.[action.ceilingId]?.height?.small === action.value)
          return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'ceiling',
          wallId: action.wallId,
          elementId: action.ceilingId,
          outer: 'height',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_CEILING_WIDTH_BIG':
        if (state.custom?.wall?.[action.wallId]?.ceiling?.[action.ceilingId]?.width?.big === action.value) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'ceiling',
          wallId: action.wallId,
          elementId: action.ceilingId,
          outer: 'width',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_CEILING_WIDTH_SMALL':
        if (state.custom?.wall?.[action.wallId]?.ceiling?.[action.ceilingId]?.width?.small === action.value)
          return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'ceiling',
          wallId: action.wallId,
          elementId: action.ceilingId,
          outer: 'width',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_CEILING_IS_ERROR':
        if (state.custom?.wall?.[action.wallId]?.ceiling?.[action.ceilingId]?.isError === action.isError) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'ceiling',
          wallId: action.wallId,
          elementId: action.ceilingId,
          key: 'isError',
          value: action.isError,
        });
      case 'CLEAR_CUSTOM_OPENING':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                opening: Object.fromEntries(
                  Object.entries(state.custom?.wall?.[action.wallId]?.opening ?? {}).filter(
                    ([openingId]) => openingId !== action.openingId,
                  ),
                ),
              },
            },
          },
        };
      case 'ADD_CUSTOM_OPENING':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                opening: {
                  ...state.custom?.wall?.[action.wallId]?.opening,
                  [action.openingId ?? crypto.randomUUID()]: {},
                },
              },
            },
          },
        };
      case 'SET_CUSTOM_OPENING_HEIGHT_BIG':
        if (state.custom?.wall?.[action.wallId]?.opening?.[action.openingId]?.height?.big === action.value)
          return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'opening',
          wallId: action.wallId,
          elementId: action.openingId,
          outer: 'height',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_OPENING_HEIGHT_SMALL':
        if (state.custom?.wall?.[action.wallId]?.opening?.[action.openingId]?.height?.small === action.value)
          return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'opening',
          wallId: action.wallId,
          elementId: action.openingId,
          outer: 'height',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_OPENING_WIDTH_BIG':
        if (state.custom?.wall?.[action.wallId]?.opening?.[action.openingId]?.width?.big === action.value) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'opening',
          wallId: action.wallId,
          elementId: action.openingId,
          outer: 'width',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_OPENING_WIDTH_SMALL':
        if (state.custom?.wall?.[action.wallId]?.opening?.[action.openingId]?.width?.small === action.value)
          return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'opening',
          wallId: action.wallId,
          elementId: action.openingId,
          outer: 'width',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_OPENING_IS_ERROR':
        if (state.custom?.wall?.[action.wallId]?.opening?.[action.openingId]?.isError === action.isError) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'opening',
          wallId: action.wallId,
          elementId: action.openingId,
          key: 'isError',
          value: action.isError,
        });
      case 'CLEAR_CUSTOM_TRIM':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                trim: Object.fromEntries(
                  Object.entries(state.custom?.wall?.[action.wallId]?.trim ?? {}).filter(
                    ([trimId]) => trimId !== action.trimId,
                  ),
                ),
              },
            },
          },
        };
      case 'ADD_CUSTOM_TRIM':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                trim: {
                  ...state.custom?.wall?.[action.wallId].trim,
                  [crypto.randomUUID()]: {},
                },
              },
            },
          },
        };
      case 'SET_CUSTOM_TRIM_HEIGHT_BIG':
        if (state.custom?.wall?.[action.wallId]?.trim?.[action.trimId]?.height?.big === action.value) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'trim',
          wallId: action.wallId,
          elementId: action.trimId,
          outer: 'height',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_TRIM_HEIGHT_SMALL':
        if (state.custom?.wall?.[action.wallId]?.trim?.[action.trimId]?.height?.small === action.value) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'trim',
          wallId: action.wallId,
          elementId: action.trimId,
          outer: 'height',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_TRIM_WIDTH_BIG':
        if (state.custom?.wall?.[action.wallId]?.trim?.[action.trimId]?.width?.big === action.value) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'trim',
          wallId: action.wallId,
          elementId: action.trimId,
          outer: 'width',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_TRIM_WIDTH_SMALL':
        if (state.custom?.wall?.[action.wallId]?.trim?.[action.trimId]?.width?.small === action.value) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'trim',
          wallId: action.wallId,
          elementId: action.trimId,
          outer: 'width',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_TRIM_IS_ERROR':
        if (state.custom?.wall?.[action.wallId]?.trim?.[action.trimId]?.isError === action.isError) return state;
        return setCustomValueWithOutTrim({
          state,
          type: 'trim',
          wallId: action.wallId,
          elementId: action.trimId,
          key: 'isError',
          value: action.isError,
        });
      case 'ADD_CUSTOM_DOOR':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                door: {
                  ...state.custom?.wall?.[action.wallId]?.door,
                  [action.doorId ?? crypto.randomUUID()]: {},
                },
              },
            },
          },
        };
      case 'CLEAR_CUSTOM_DOOR':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                door: Object.fromEntries(
                  Object.entries(state.custom?.wall?.[action.wallId]?.door ?? {}).filter(
                    ([doorId]) => doorId !== action.doorId,
                  ),
                ),
              },
            },
          },
        };
      case 'SET_CUSTOM_DOOR_HEIGHT_BIG':
        if (state.custom?.wall?.[action.wallId]?.door?.[action.doorId]?.height?.big === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'door',
          wallId: action.wallId,
          elementId: action.doorId,
          outer: 'height',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_DOOR_HEIGHT_SMALL':
        if (state.custom?.wall?.[action.wallId]?.door?.[action.doorId]?.height?.small === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'door',
          wallId: action.wallId,
          elementId: action.doorId,
          outer: 'height',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_DOOR_WIDTH_BIG':
        if (state.custom?.wall?.[action.wallId]?.door?.[action.doorId]?.width?.big === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'door',
          wallId: action.wallId,
          elementId: action.doorId,
          outer: 'width',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_DOOR_WIDTH_SMALL':
        if (state.custom?.wall?.[action.wallId]?.door?.[action.doorId]?.width?.small === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'door',
          wallId: action.wallId,
          elementId: action.doorId,
          outer: 'width',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_DOOR_HAS_TRIM':
        return setCustomValueWithTrim({
          state,
          type: 'door',
          wallId: action.wallId,
          elementId: action.doorId,
          key: 'hasTrim',
          value: !state.custom?.wall?.[action.wallId]?.door?.[action.doorId]?.hasTrim,
        });
      case 'SET_CUSTOM_DOOR_TRIM_SMALL':
        if (state.custom?.wall?.[action.wallId]?.door?.[action.doorId]?.trim?.small === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'door',
          wallId: action.wallId,
          elementId: action.doorId,
          outer: 'trim',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_DOOR_IS_ERROR':
        if (state.custom?.wall?.[action.wallId]?.door?.[action.doorId]?.isError === action.isError) return state;
        return setCustomValueWithTrim({
          state,
          type: 'door',
          wallId: action.wallId,
          elementId: action.doorId,
          key: 'isError',
          value: action.isError,
        });
      case 'ADD_CUSTOM_WINDOW':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                window: {
                  ...state.custom?.wall?.[action.wallId].window,
                  [crypto.randomUUID()]: {},
                },
              },
            },
          },
        };
      case 'CLEAR_CUSTOM_WINDOW':
        return {
          ...state,
          custom: {
            ...state.custom,
            wall: {
              ...state.custom?.wall,
              [action.wallId]: {
                ...state.custom?.wall?.[action.wallId],
                window: Object.fromEntries(
                  Object.entries(state.custom?.wall?.[action.wallId]?.window ?? {}).filter(
                    ([windowId]) => windowId !== action.windowId,
                  ),
                ),
              },
            },
          },
        };
      case 'SET_CUSTOM_WINDOW_HEIGHT_BIG':
        if (state.custom?.wall?.[action.wallId]?.window?.[action.windowId]?.height?.big === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'window',
          wallId: action.wallId,
          elementId: action.windowId,
          outer: 'height',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_WINDOW_HEIGHT_SMALL':
        if (state.custom?.wall?.[action.wallId]?.window?.[action.windowId]?.height?.small === action.value)
          return state;
        return setCustomValueWithTrim({
          state,
          type: 'window',
          wallId: action.wallId,
          elementId: action.windowId,
          outer: 'height',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_WINDOW_WIDTH_BIG':
        if (state.custom?.wall?.[action.wallId]?.window?.[action.windowId]?.width?.big === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'window',
          wallId: action.wallId,
          elementId: action.windowId,
          outer: 'width',
          inner: 'big',
          value: action.value,
        });
      case 'SET_CUSTOM_WINDOW_WIDTH_SMALL':
        if (state.custom?.wall?.[action.wallId]?.window?.[action.windowId]?.width?.small === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'window',
          wallId: action.wallId,
          elementId: action.windowId,
          outer: 'width',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_WINDOW_HAS_TRIM':
        return setCustomValueWithTrim({
          state,
          type: 'window',
          wallId: action.wallId,
          elementId: action.windowId,
          key: 'hasTrim',
          value: !state.custom?.wall?.[action.wallId]?.window?.[action.windowId]?.hasTrim,
        });
      case 'SET_CUSTOM_WINDOW_TRIM_SMALL':
        if (state.custom?.wall?.[action.wallId]?.window?.[action.windowId]?.trim?.small === action.value) return state;
        return setCustomValueWithTrim({
          state,
          type: 'window',
          wallId: action.wallId,
          elementId: action.windowId,
          outer: 'trim',
          inner: 'small',
          value: action.value,
        });
      case 'SET_CUSTOM_WINDOW_IS_ERROR':
        if (state.custom?.wall?.[action.wallId]?.window?.[action.windowId]?.isError === action.isError) return state;
        return setCustomValueWithTrim({
          state,
          type: 'window',
          wallId: action.wallId,
          elementId: action.windowId,
          key: 'isError',
          value: action.isError,
        });
      case 'SET_CUSTOM_OVERALL_AREA':
        if (state.custom?.area?.area === action.wallArea) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            area: {
              ...state.custom?.area,
              area: action.wallArea,
            },
          },
        };
      case 'SET_CUSTOM_OVERALL_AREA_WITH_TEXTURE':
        if (state.custom?.area?.areaWithTexture === action.wallAreaWithTexture) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            area: {
              ...state.custom?.area,
              areaWithTexture: action.wallAreaWithTexture,
            },
          },
        };
      case 'SET_CUSTOM_OVERALL_TRIM':
        if (state.custom?.trim?.area === action.trimArea) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            trim: {
              ...state.custom?.trim,
              area: action.trimArea,
            },
          },
        };
      case 'SET_CUSTOM_OVERALL_CEILING':
        if (state.custom?.ceiling?.area === action.ceilingArea) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            ceiling: {
              ...state.custom?.ceiling,
              area: action.ceilingArea,
            },
          },
        };
      case 'SET_CUSTOM_OVERALL_AREA_COVERAGE':
        if (state.custom?.area?.coverage === action.coverage) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            area: {
              ...state.custom?.area,
              coverage: action.coverage,
            },
          },
        };
      case 'SET_CUSTOM_OVERALL_TRIM_COVERAGE':
        if (state.custom?.trim?.coverage === action.coverage) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            trim: {
              ...state.custom?.trim,
              coverage: action.coverage,
            },
          },
        };
      case 'SET_CUSTOM_OVERALL_CEILING_COVERAGE':
        if (state.custom?.ceiling?.coverage === action.coverage) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            ceiling: {
              ...state.custom?.ceiling,
              coverage: action.coverage,
            },
          },
        };
      case 'SET_CUSTOM_AREA_COATS':
        if (state.custom?.area?.coats === action.coats) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            area: {
              ...state.custom?.area,
              coats: action.coats,
            },
          },
        };
      case 'SET_CUSTOM_CEILING_COATS':
        if (state.custom?.ceiling?.coats === action.coats) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            ceiling: {
              ...state.custom?.ceiling,
              coats: action.coats,
            },
          },
        };
      case 'SET_CUSTOM_TRIM_COATS':
        if (state.custom?.trim?.coats === action.coats) return state;
        return {
          ...state,
          custom: {
            ...(state.custom ?? { wall: {} }),
            trim: {
              ...state.custom?.trim,
              coats: action.coats,
            },
          },
        };
      default:
        console.warn('Unknown type', action);
        return state;
    }
  }

  useEffect(() => {
    if (Object.keys(state).length === 0 || !state.sessionStorageChecked) {
      const fromSessionStorage = sessionStorage.getItem(SESSION_STORAGE_KEY);
      if (fromSessionStorage) {
        dispatch({
          type: 'INITIALIZE',
          newState: JSON.parse(fromSessionStorage),
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          newState: { sessionStorageChecked: true },
        });
      }
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(state));
    }
    customArea(state.custom?.wall ?? {}, state.unit ?? 'imperial', dispatch);
    customCoverage(state, dispatch);
  }, [state]);

  return (
    <PaintCalculatorState.Provider value={state}>
      <PaintCalculatorDispatch.Provider value={dispatch}>{children}</PaintCalculatorDispatch.Provider>
    </PaintCalculatorState.Provider>
  );
};
