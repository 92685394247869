import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export interface Translations {
  Baseboard: string;
  BaseboardImage: string;
  CEILING: string;
  CEILING_RESULTS: string;
  Ceiling: string;
  Centimeters: string;
  Count: string;
  CrownMolding: string;
  DOOR: string;
  Door: string;
  DoorHasTrim: string;
  Doors: string;
  DoorwayImage: string;
  EMAIL_ME_MY_RESULTS: string;
  EXTERIOR: string;
  EmailAddress: string;
  ExtraCoarse: string;
  Feet: string;
  Fine: string;
  Height: string;
  INTERIOR: string;
  ImperialUnitOfArea: string;
  ImperialUnitOfVolume: string;
  Inches: string;
  Length: string;
  Medium: string;
  Meters: string;
  MetricUnitOfArea: string;
  MetricUnitOfVolume: string;
  NumberOfCoats: string;
  OPENING: string;
  Opening: string;
  PeakHeight: string;
  Privacy: string;
  PrivacyLinkText: string;
  Recalculate: string;
  Resend: string;
  SIDE: string;
  Sent: string;
  StartOver: string;
  Submit: string;
  TRIM: string;
  TRIM_RESULTS: string;
  Texture: string;
  TextureImage: string;
  TotalCeilingArea: string;
  TotalCeilingPaint: string;
  TotalCeilingPrimer: string;
  TotalHeightImage: string;
  TotalLengthImage: string;
  TotalTrimArea: string;
  TotalTrimPaint: string;
  TotalTrimPrimer: string;
  TotalWallArea: string;
  TotalWallPaint: string;
  TotalWallPrimer: string;
  Trim: string;
  TrimImage: string;
  TrimWidth: string;
  WALL: string;
  WALL_RESULTS: string;
  WINDOW: string;
  WallImage: string;
  Width: string;
  Window: string;
  WindowHasTrim: string;
  WindowImage: string;
  Windows: string;
  ceilingTooltip: string;
  doorImage: string;
  doorTooltip: string;
  exterior: string;
  gallon: string;
  interior: string;
  liter: string;
  openingTooltip: string;
  paint: string;
  patience: string;
  primer: string;
  privacyError: string;
  squareFeet: string;
  squareMeters: string;
  trimTooltip: string;
  trimWidth: string;
  windowTooltip: string;
  x150x90: string;
  x210x90: string;
  x5x3: string;
  x7x3: string;
}

export const useTranslations = (brand = ''): Translations => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [translations, setTranslations] = useState<Translations>({} as Translations);
  const intl = useIntl();

  useEffect(() => {
    const newTranslations: Translations = {
      Baseboard: intl.formatMessage({ defaultMessage: 'Baseboard' }),
      BaseboardImage: intl.formatMessage({ defaultMessage: 'Baseboards Image' }),
      CEILING: intl.formatMessage({ defaultMessage: 'CEILING' }),
      CEILING_RESULTS: intl.formatMessage({ defaultMessage: 'CEILING RESULTS' }),
      Ceiling: intl.formatMessage({ defaultMessage: 'Ceiling' }),
      Centimeters: intl.formatMessage({ defaultMessage: 'Centimeters' }),
      Count: intl.formatMessage({ defaultMessage: 'Count' }),
      CrownMolding: intl.formatMessage({ defaultMessage: 'Crown Molding' }),
      DOOR: intl.formatMessage({ defaultMessage: 'DOOR' }),
      Door: intl.formatMessage({ defaultMessage: 'Door' }),
      DoorHasTrim: intl.formatMessage({ defaultMessage: 'Does this door have trim?' }),
      Doors: intl.formatMessage({ defaultMessage: 'Doors' }),
      DoorwayImage: intl.formatMessage({ defaultMessage: 'Doorways Image' }),
      EMAIL_ME_MY_RESULTS: intl.formatMessage({ defaultMessage: 'EMAIL ME MY RESULTS' }),
      EXTERIOR: intl.formatMessage({ defaultMessage: 'EXTERIOR' }),
      EmailAddress: intl.formatMessage({ defaultMessage: 'Email address' }),
      ExtraCoarse: intl.formatMessage({ defaultMessage: 'Extra Coarse' }),
      Feet: intl.formatMessage({ defaultMessage: 'Feet' }),
      Fine: intl.formatMessage({ defaultMessage: 'Fine' }),
      Height: intl.formatMessage({ defaultMessage: 'Height' }),
      INTERIOR: intl.formatMessage({ defaultMessage: 'INTERIOR' }),
      ImperialUnitOfArea: intl.formatMessage({ defaultMessage: 'sq ft' }),
      ImperialUnitOfVolume: intl.formatMessage({ defaultMessage: 'gallons' }),
      Inches: intl.formatMessage({ defaultMessage: 'Inches' }),
      Length: intl.formatMessage({ defaultMessage: 'Length' }),
      Medium: intl.formatMessage({ defaultMessage: 'Medium' }),
      Meters: intl.formatMessage({ defaultMessage: 'Meters' }),
      MetricUnitOfArea: intl.formatMessage({ defaultMessage: 'sq m' }),
      MetricUnitOfVolume: intl.formatMessage({ defaultMessage: 'liters' }),
      NumberOfCoats: intl.formatMessage({ defaultMessage: 'Number of coats' }),
      OPENING: intl.formatMessage({ defaultMessage: 'OPENING' }),
      Opening: intl.formatMessage({ defaultMessage: 'Opening' }),
      PeakHeight: intl.formatMessage({ defaultMessage: 'Peak Height' }),
      Privacy: intl.formatMessage(
        {
          defaultMessage:
            'By submitting this form, I agree that { brand } will use the details provided here to respond to my inquiry.',
        },
        { brand },
      ),
      PrivacyLinkText: intl.formatMessage({ defaultMessage: 'We value your privacy.' }),
      Recalculate: intl.formatMessage({ defaultMessage: 'Recalculate' }),
      Resend: intl.formatMessage({ defaultMessage: 'Resend' }),
      SIDE: intl.formatMessage({ defaultMessage: 'SIDE' }),
      Sent: intl.formatMessage({ defaultMessage: 'Sent!' }),
      StartOver: intl.formatMessage({ defaultMessage: 'Start Over' }),
      Submit: intl.formatMessage({ defaultMessage: 'Submit' }),
      TRIM: intl.formatMessage({ defaultMessage: 'TRIM' }),
      TRIM_RESULTS: intl.formatMessage({ defaultMessage: 'TRIM RESULTS' }),
      Texture: intl.formatMessage({ defaultMessage: 'Texture' }),
      TextureImage: intl.formatMessage({ defaultMessage: 'Texture Image' }),
      TotalCeilingArea: intl.formatMessage({ defaultMessage: 'Total Ceiling Area' }),
      TotalCeilingPaint: intl.formatMessage({ defaultMessage: 'Total Ceiling Paint' }),
      TotalCeilingPrimer: intl.formatMessage({ defaultMessage: 'Total Ceiling Primer' }),
      TotalHeightImage: intl.formatMessage({ defaultMessage: 'Total Height Image' }),
      TotalLengthImage: intl.formatMessage({ defaultMessage: 'Total Length Image' }),
      TotalTrimArea: intl.formatMessage({ defaultMessage: 'Total Trim Area' }),
      TotalTrimPaint: intl.formatMessage({ defaultMessage: 'Total Trim Paint' }),
      TotalTrimPrimer: intl.formatMessage({ defaultMessage: 'Total Trim Primer' }),
      TotalWallArea: intl.formatMessage({ defaultMessage: 'Total Wall Area' }),
      TotalWallPaint: intl.formatMessage({ defaultMessage: 'Total Wall Paint' }),
      TotalWallPrimer: intl.formatMessage({ defaultMessage: 'Total Wall Primer' }),
      Trim: intl.formatMessage({ defaultMessage: 'Trim' }),
      TrimImage: intl.formatMessage({ defaultMessage: 'Trim Image' }),
      TrimWidth: intl.formatMessage({ defaultMessage: 'Trim Width' }),
      WALL: intl.formatMessage({ defaultMessage: 'WALL' }),
      WALL_RESULTS: intl.formatMessage({ defaultMessage: 'WALL RESULTS' }),
      WINDOW: intl.formatMessage({ defaultMessage: 'WINDOW' }),
      WindowHasTrim: intl.formatMessage({ defaultMessage: 'Does this window have trim?' }),
      WallImage: intl.formatMessage({ defaultMessage: 'Wall Image' }),
      Width: intl.formatMessage({ defaultMessage: 'Width' }),
      Window: intl.formatMessage({ defaultMessage: 'Window' }),
      WindowImage: intl.formatMessage({ defaultMessage: 'Window Image' }),
      Windows: intl.formatMessage({ defaultMessage: 'Windows' }),
      ceilingTooltip: intl.formatMessage(
        { defaultMessage: 'Add {component} to {wall}' },
        {
          component: intl.formatMessage({ defaultMessage: 'ceiling' }),
          wall: intl.formatMessage({ defaultMessage: 'wall' }),
        },
      ),
      doorImage: intl.formatMessage({ defaultMessage: 'Door Image' }),
      doorTooltip: intl.formatMessage(
        { defaultMessage: 'Add {component} to {wall}' },
        {
          component: intl.formatMessage({ defaultMessage: 'door' }),
          wall: intl.formatMessage({ defaultMessage: 'wall' }),
        },
      ),
      exterior: intl.formatMessage({ defaultMessage: 'exterior' }),
      gallon: intl.formatMessage({ defaultMessage: 'gallon' }),
      interior: intl.formatMessage({ defaultMessage: 'interior' }),
      liter: intl.formatMessage({ defaultMessage: 'liter' }),
      openingTooltip: intl.formatMessage(
        { defaultMessage: 'Add {component} to {wall}' },
        {
          component: intl.formatMessage({ defaultMessage: 'opening' }),
          wall: intl.formatMessage({ defaultMessage: 'wall' }),
        },
      ),
      paint: intl.formatMessage({ defaultMessage: 'paint' }),
      patience: intl.formatMessage({ defaultMessage: 'You should be receiving your results soon.' }),
      primer: intl.formatMessage({ defaultMessage: 'primer' }),
      privacyError: intl.formatMessage({ defaultMessage: 'Please check the box in order to submit the form.' }),
      squareFeet: intl.formatMessage({ defaultMessage: 'square feet' }),
      squareMeters: intl.formatMessage({ defaultMessage: 'square meters' }),
      trimTooltip: intl.formatMessage(
        { defaultMessage: 'Add {component} to {wall}' },
        {
          component: intl.formatMessage({ defaultMessage: 'trim' }),
          wall: intl.formatMessage({ defaultMessage: 'wall' }),
        },
      ),
      trimWidth: intl.formatMessage({ defaultMessage: 'Trim Width' }),
      windowTooltip: intl.formatMessage(
        { defaultMessage: 'Add {component} to {wall}' },
        {
          component: intl.formatMessage({ defaultMessage: 'window' }),
          wall: intl.formatMessage({ defaultMessage: 'wall' }),
        },
      ),
      x150x90: intl.formatMessage({ defaultMessage: '90cm x 150cm' }),
      x210x90: intl.formatMessage({ defaultMessage: '90cm x 210cm' }),
      x5x3: intl.formatMessage({ defaultMessage: "3' x 5'" }),
      x7x3: intl.formatMessage({ defaultMessage: "3' x 7'" }),
    };
    setTranslations(newTranslations);
  }, [brand, intl]);

  return translations;
};
