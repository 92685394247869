import React, { useEffect } from 'react';
import { NavigateFunction, Params } from 'react-router-dom';
import { Action, PaintCalculatorStore } from '../context/paint-calculator.context';
import { CALCULATION, PAINT_TYPE, SPACE, UNITS_OF_MEASUREMENT } from '../../shared/types/paint-calculator/types';

export const useReadParams = ({
  calculation,
  dispatch,
  navigate,
  params,
  store,
}: {
  calculation?: CALCULATION;
  dispatch: React.Dispatch<Action> | null;
  navigate: NavigateFunction;
  params: Readonly<Params<string>>;
  store: PaintCalculatorStore;
}) => {
  useEffect(() => {
    if (dispatch) {
      if (
        store.calculation === undefined ||
        ((calculation ?? params.calculation) && store.calculation !== (calculation ?? params.calculation))
      ) {
        dispatch({ type: 'SET_CALCULATION', calculation: (calculation ?? params.calculation) as CALCULATION });
      }
      if (store.space === undefined || (params.space && store.space !== params.space)) {
        dispatch({ type: 'SET_SPACE', space: (params.space as SPACE) ?? 'interior' });
      }
      if (store.paintType === undefined || (params.paintType && store.paintType !== params.paintType)) {
        dispatch({ type: 'SET_PAINT_TYPE', paintType: (params.paintType as PAINT_TYPE) ?? 'paint' });
      }
      if (store.unit === undefined || (params.unit && store.unit !== params.unit)) {
        if (params.unit) {
          dispatch({
            type: 'SET_UNITS_OF_MEASUREMENT',
            unit: params.unit as UNITS_OF_MEASUREMENT,
          });
        } else {
          navigate('/measurement-picker');
        }
      }
    }
  }, [calculation, dispatch, navigate, params, store]);
};
