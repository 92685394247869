import rectangularArea from './rectangularArea';
import { PAINT_TYPE, SPACE, UNITS_OF_MEASUREMENT } from '../../shared/types/paint-calculator/types';
import { CONSTANTS } from '../constants/constants';

export const quickArea = (
  unit: UNITS_OF_MEASUREMENT,
  bigHeight: number,
  smallHeight: number,
  bigWidth: number,
  smallWidth: number,
  doors: number,
  windows: number,
): [number, boolean] => {
  const byUnit = unit === 'imperial' ? CONSTANTS.IMPERIAL : CONSTANTS.METRIC;
  const wallArea = rectangularArea(bigHeight, smallHeight, bigWidth, smallWidth, byUnit?.CONVERSION);
  const doorsArea =
    doors *
    rectangularArea(
      byUnit?.QUICK?.DOOR?.HEIGHT?.BIG,
      byUnit?.QUICK?.DOOR?.HEIGHT?.SMALL,
      byUnit?.QUICK?.DOOR?.WIDTH?.BIG,
      byUnit?.QUICK?.DOOR?.WIDTH?.SMALL,
      byUnit?.CONVERSION,
    );
  const windowsArea =
    windows *
    rectangularArea(
      byUnit?.QUICK?.WINDOW?.HEIGHT?.BIG,
      byUnit?.QUICK?.WINDOW?.HEIGHT?.SMALL,
      byUnit?.QUICK?.WINDOW?.WIDTH?.BIG,
      byUnit?.QUICK?.WINDOW?.WIDTH?.SMALL,
      byUnit?.CONVERSION,
    );
  const totalArea = wallArea - doorsArea - windowsArea;
  return [totalArea, totalArea < 0];
};

export const quickCoverage = (
  unit: UNITS_OF_MEASUREMENT,
  area: number,
  paintType: PAINT_TYPE,
  space: SPACE,
): [number, boolean] => {
  const byUnit = unit === 'imperial' ? CONSTANTS.IMPERIAL : CONSTANTS.METRIC;
  const coverage =
    paintType === 'primer'
      ? byUnit?.COVERAGE?.PRIMER
      : space === 'interior'
        ? byUnit?.COVERAGE?.INTERIOR
        : byUnit?.COVERAGE?.EXTERIOR;
  const volume = (space === 'interior' ? byUnit?.VOLUME : byUnit?.VOLUME) ?? 1;
  const calculation = coverage ? area / (coverage / volume) : 0;
  return [calculation, calculation < 0];
};
