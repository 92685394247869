import React, { FC, useContext, useEffect } from 'react';
import CustomDisclaimerWrapper from '../../components/disclaimer/custom-disclaimer-wrapper';
import Email from '../../components/email/email';
import QuickDisclaimerWrapper from '../../components/disclaimer/quick-disclaimer-wrapper';
import Result from '../../components/result/result';
import Title from '../../components/title/title';
import type { PaintCalculatorAuthoredData } from '../../../shared/types/paint-calculator/types';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useReadParams } from '../../hooks/use-read-params';

const Results: FC = () => {
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);
  const [authoredData] = useLoaderData() as [PaintCalculatorAuthoredData];
  const navigate = useNavigate();
  const params = useParams();

  useReadParams({ dispatch, navigate, params, store });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (store.calculation && store.unit) {
        if ((store[store.calculation]?.area?.area ?? 0) <= 0) {
          navigate(`/${store.calculation}/${store.space ?? 'interior'}/${store.paintType ?? 'paint'}/${store.unit}`);
        }
      } else {
        navigate('/measurement-picker');
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [navigate, store]);

  return (
    <div className='pc-results'>
      <section className='pc-results__title'>
        <Title text={<FormattedMessage defaultMessage='YOUR RESULTS' />} />
      </section>
      <section className='pc-results__container'>
        <Result />
      </section>
      {authoredData.campaignId && (
        <section className='pc-results__container'>
          <Email {...authoredData} />
        </section>
      )}
      <section className='pc-results__container'>
        {store.calculation === 'quick' ? <QuickDisclaimerWrapper /> : <CustomDisclaimerWrapper />}
      </section>
    </div>
  );
};

export default Results;
