import { Dispatch } from 'react';
import rectangularArea from './rectangularArea';
import triangularArea from './triangularArea';
import { Action, PaintCalculatorStore, WallData } from '../context/paint-calculator.context';
import { CONSTANTS } from '../constants/constants';
import { UNITS_OF_MEASUREMENT } from '../../shared/types/paint-calculator/types';
import { forceNumeric } from '../../shared/utils/forceNumeric';

interface WallElements {
  feature: 'door' | 'window' | 'opening' | 'trim';
  featureTrim?: 'doorTrim' | 'windowTrim';
}

interface CalculatedValues {
  area: number;
  coverage: number;
}

interface CalculatedWallAreaParts {
  area?: number;
  baseboard?: number;
  molding?: number;
  ceiling?: Record<string, number>;
  door?: Record<string, number>;
  doorTrim?: Record<string, number>;
  opening?: Record<string, number>;
  trim?: Record<string, number>;
  window?: Record<string, number>;
  windowTrim?: Record<string, number>;
}

interface CalculatedWallArea {
  wallParts: Record<string, CalculatedWallAreaParts>;
  wall?: CalculatedValues;
  ceiling?: CalculatedValues;
  trim?: CalculatedValues;
}

export const customArea = (walls: Record<string, WallData>, unit: UNITS_OF_MEASUREMENT, dispatch: Dispatch<Action>) => {
  const { CONVERSION } = (unit === 'imperial' ? CONSTANTS.IMPERIAL : CONSTANTS.METRIC) ?? {};

  const calculatedArea = Object.entries(walls).reduce<CalculatedWallArea>(
    (wallA, [wallId, wallData]) => {
      return {
        ...wallA,
        wallParts: {
          ...wallA.wallParts,
          [wallId]: {
            ...wallA.wallParts[wallId],
            area:
              rectangularArea(
                wallData.height?.big,
                wallData.height?.small,
                wallData.width?.big,
                wallData.width?.small,
                CONVERSION,
              ) +
              (wallData.hasPeak
                ? triangularArea(
                    wallData.peak?.big,
                    wallData.peak?.small,
                    wallData.width?.big,
                    wallData.width?.small,
                    CONVERSION,
                  )
                : 0),
            baseboard: rectangularArea(
              0,
              wallData.baseboard?.small,
              wallData.width?.big,
              wallData.width?.small,
              CONVERSION,
            ),
            molding: rectangularArea(
              0,
              wallData.molding?.small,
              wallData.width?.big,
              wallData.width?.small,
              CONVERSION,
            ),
            door: Object.entries(walls[wallId].door ?? {}).reduce<Record<string, number>>(
              (doorA, [doorId, doorData]) => {
                return {
                  ...doorA,
                  [doorId]: rectangularArea(
                    doorData.height?.big,
                    doorData.height?.small,
                    doorData.width?.big,
                    doorData.width?.small,
                    CONVERSION,
                  ),
                };
              },
              {},
            ),
            doorTrim: Object.entries(walls[wallId].door ?? {}).reduce<Record<string, number>>(
              (doorA, [doorId, doorData]) => {
                return {
                  ...doorA,
                  [doorId]: doorData.hasTrim
                    ? rectangularArea(0, doorData.trim?.small, doorData.width?.big, doorData.width?.small, CONVERSION) +
                      rectangularArea(
                        0,
                        doorData.trim?.small,
                        doorData.height?.big,
                        doorData.height?.small,
                        CONVERSION,
                      ) *
                        2
                    : 0,
                };
              },
              {},
            ),
            window: Object.entries(walls[wallId].window ?? {}).reduce<Record<string, number>>(
              (windowA, [windowId, windowData]) => {
                return {
                  ...windowA,
                  [windowId]: rectangularArea(
                    windowData.height?.big,
                    windowData.height?.small,
                    windowData.width?.big,
                    windowData.width?.small,
                    CONVERSION,
                  ),
                };
              },
              {},
            ),
            windowTrim: Object.entries(walls[wallId].window ?? {}).reduce<Record<string, number>>(
              (windowA, [windowId, windowData]) => {
                return {
                  ...windowA,
                  [windowId]: windowData.hasTrim
                    ? rectangularArea(
                        0,
                        windowData.trim?.small,
                        windowData.width?.big,
                        windowData.width?.small,
                        CONVERSION,
                      ) *
                        2 +
                      rectangularArea(
                        0,
                        windowData.trim?.small,
                        windowData.height?.big,
                        windowData.height?.small,
                        CONVERSION,
                      ) *
                        2
                    : 0,
                };
              },
              {},
            ),
            opening: Object.entries(walls[wallId].opening ?? {}).reduce<Record<string, number>>(
              (openingA, [openingId, openingData]) => {
                return {
                  ...openingA,
                  [openingId]: rectangularArea(
                    openingData.height?.big,
                    openingData.height?.small,
                    openingData.width?.big,
                    openingData.width?.small,
                    CONVERSION,
                  ),
                };
              },
              {},
            ),
            trim: Object.entries(walls[wallId].trim ?? {}).reduce<Record<string, number>>(
              (trimA, [trimId, trimData]) => {
                return {
                  ...trimA,
                  [trimId]: rectangularArea(
                    trimData.height?.big,
                    trimData.height?.small,
                    trimData.width?.big,
                    trimData.width?.small,
                    CONVERSION,
                  ),
                };
              },
              {},
            ),
            ceiling: Object.entries(walls[wallId].ceiling ?? {}).reduce<Record<string, number>>(
              (ceilingA, [ceilingId, ceilingData]) => {
                return {
                  ...ceilingA,
                  [ceilingId]: rectangularArea(
                    ceilingData.height?.big,
                    ceilingData.height?.small,
                    ceilingData.width?.big,
                    ceilingData.width?.small,
                    CONVERSION,
                  ),
                };
              },
              {},
            ),
          },
        },
      };
    },
    { wallParts: {} },
  );
  let wallArea = 0;
  let wallAreaWithTexture = 0;
  let trimArea = 0;
  let ceilingArea = 0;
  Object.entries(calculatedArea.wallParts).forEach(([wallId, wallData]) => {
    let area = (wallData.area ?? 0) - (wallData.baseboard ?? 0) - (wallData.molding ?? 0);
    trimArea += (wallData.baseboard ?? 0) + (wallData.molding ?? 0);

    let totalCutoutArea = 0;
    let totalTrimArea = 0;

    const wallElements: WallElements[] = [
      { feature: 'door', featureTrim: 'doorTrim' },
      { feature: 'window', featureTrim: 'windowTrim' },
      { feature: 'opening' },
      { feature: 'trim' },
    ];

    wallElements.forEach(({ feature, featureTrim }) => {
      Object.entries(wallData[feature] ?? {}).forEach(([elementId, elementArea]) => {
        totalCutoutArea += elementArea;
        totalTrimArea += featureTrim ? (wallData[featureTrim]?.[elementId] ?? 0) : 0;
      });
    });

    if (area - totalCutoutArea - totalTrimArea >= 0) {
      area -= totalCutoutArea + totalTrimArea;
      trimArea += totalTrimArea;
      dispatch({ type: 'SET_CUSTOM_WALL_IS_ERROR', wallId, isError: false });
    } else {
      dispatch({ type: 'SET_CUSTOM_WALL_IS_ERROR', wallId, isError: true });
    }

    Object.entries(wallData.ceiling ?? {}).forEach(([_, thisCeilingArea]) => {
      ceilingArea += thisCeilingArea;
      dispatch({ type: 'SET_CUSTOM_WALL_IS_ERROR', wallId, isError: false });
    });

    // This calculation takes into the account the texture only after all the cutouts have been removed
    wallAreaWithTexture = wallAreaWithTexture + area * forceNumeric(walls[wallId]?.texture ?? 1);
    wallArea = wallArea + area;
  });
  dispatch({ type: 'SET_CUSTOM_OVERALL_AREA', wallArea });
  dispatch({ type: 'SET_CUSTOM_OVERALL_AREA_WITH_TEXTURE', wallAreaWithTexture });
  dispatch({ type: 'SET_CUSTOM_OVERALL_TRIM', trimArea });
  dispatch({ type: 'SET_CUSTOM_OVERALL_CEILING', ceilingArea });
};

export const customCoverage = (store: PaintCalculatorStore, dispatch: Dispatch<Action>) => {
  const byUnit = store.unit === 'imperial' ? CONSTANTS.IMPERIAL?.COVERAGE : CONSTANTS.METRIC?.COVERAGE;
  const coverage =
    store.paintType === 'primer' ? byUnit?.PRIMER : store.space === 'interior' ? byUnit?.INTERIOR : byUnit?.EXTERIOR;
  if (coverage) {
    dispatch({
      type: 'SET_CUSTOM_OVERALL_AREA_COVERAGE',
      coverage: (store.custom?.area?.areaWithTexture ?? 0) / coverage,
    });
    dispatch({
      type: 'SET_CUSTOM_OVERALL_TRIM_COVERAGE',
      coverage: (store.custom?.trim?.area ?? 0) / coverage,
    });
    dispatch({
      type: 'SET_CUSTOM_OVERALL_CEILING_COVERAGE',
      coverage: (store.custom?.ceiling?.area ?? 0) / coverage,
    });
  }
};
