import React, { FC, useContext } from 'react';
import './quick-help.scss';
import Button from '../../../components/button/button';
import InfoIcon from '../../../components/info-icon/infoIcon';
import { FaTimesCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorAuthoredData } from '../../../../shared/types/paint-calculator/types';
import { PaintCalculatorState } from '../../../context/paint-calculator.context';
import { useLoaderData } from 'react-router-dom';
import { useTranslations } from '../../../hooks/use-translations';

const QuickHelpInterior: FC = () => {
  const [authoredData] = useLoaderData() as [PaintCalculatorAuthoredData];
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);

  return (
    <article>
      <section className='pc-quick-help-title-section'>
        <h2>
          <FormattedMessage
            defaultMessage='Help With {location} Calculations'
            values={{ location: <FormattedMessage defaultMessage='Interior' /> }}
          />
        </h2>
        <InfoIcon
          route='/quick/interior'
          tooltipText={
            <FormattedMessage
              defaultMessage='Return to {location} form'
              values={{ location: <FormattedMessage defaultMessage='interior' /> }}
            />
          }
        >
          <FaTimesCircle />
        </InfoIcon>
      </section>
      <section className='pc-quick-help-content-section'>
        <h3>
          <FormattedMessage defaultMessage='Total Height' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Interior Quick Total Height')
              ?.fileReference
          }
          alt={translations.TotalHeightImage}
        />
        <p>
          <FormattedMessage
            defaultMessage='Pick one wall in your space and measure from floor to ceiling.
              If Wall heights vary in your space, provide the average wall height by adding 
              the measurement of two walls together and dividing by two.'
          />
        </p>
        <h3>
          <FormattedMessage defaultMessage='Total Length' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Interior Quick Total Length')
              ?.fileReference
          }
          alt={translations.TotalLengthImage}
        />
        <p>
          <FormattedMessage
            defaultMessage='Measure the length of each wall and add each wall and add each 
              wall measurement together to find the total length around your room.'
          />
        </p>
        <h3>
          <FormattedMessage defaultMessage='Windows' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Interior Quick Windows')?.fileReference
          }
          alt={translations.WindowImage}
        />
        <p>
          {store.unit === 'imperial' ? (
            <FormattedMessage
              defaultMessage="Write down the number of windows in your space, keeping in mind 
              the Paint Calculator assumes a window size of 5'x3'. Use the custom calculation 
              if your windows are not close to 5'x3'."
            />
          ) : (
            <FormattedMessage
              defaultMessage='Write down the number of windows in your space, keeping in mind 
              the Paint Calculator assumes a window size of 150cm X 90cm. Use the custom calculation 
              if your windows are not close to 150cm X 90cm.'
            />
          )}
        </p>
        <h3>
          <FormattedMessage defaultMessage='Doors' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Interior Quick Doors')?.fileReference
          }
          alt={translations.doorImage}
        />
        <p>
          {store.unit === 'imperial' ? (
            <FormattedMessage
              defaultMessage="Select the number of doors in your space, keeping in mind the 
              Paint Calculator assumes a door size of 7'x3'. Use the custom calculation if 
              your doors are not close to 7'x3'."
            />
          ) : (
            <FormattedMessage
              defaultMessage='Select the number of doors in your space, keeping in mind the 
              Paint Calculator assumes a door size 210cm X 90cm. Use the custom calculation if 
              your doors are not close to 210cm X 90cm.'
            />
          )}
        </p>
      </section>
      <section className='pc-quick-help-button'>
        <Button style='button' route='/quick/interior' isInternal>
          <FormattedMessage defaultMessage='Close' />
        </Button>
      </section>
    </article>
  );
};

export default QuickHelpInterior;
