import React, { FC, Suspense, useEffect, useState } from 'react';
import './paint-calculator.scss';
import Custom from '../pages/custom/custom';
import CustomHelpExterior from '../pages/custom/help/custom-help-exterior';
import CustomHelpInterior from '../pages/custom/help/custom-help-interior';
import MeasurementPicker from '../pages/measurement-picker/measurement-picker';
import Quick from '../pages/quick/quick';
import QuickHelpExterior from '../pages/quick/help/quick-help-exterior';
import QuickHelpInterior from '../pages/quick/help/quick-help-interior';
import Results from '../pages/results/results';
import Spinner from '../../shared/componets/spinner';
import { PaintCalculatorAuthoredData, TranslationData } from '../../shared/types/paint-calculator/types';
import useGetData, { type GetDataResource } from '../../shared/hooks/use-get-data';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Locale } from '../../shared/types/localeTypes';
import { PaintCalculatorProvider } from '../context/paint-calculator.context';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { getDictionary } from '../translations/util/dictionary';

export interface PaintCalculatorContainerProps {
  slingModelAPI: string;
}

interface PaintCalculatorProps {
  authoredData: PaintCalculatorAuthoredData;
  translationResource: [GetDataResource<TranslationData> | null, AbortController];
}

interface ReadAuthoringProps {
  authorResource: [GetDataResource<PaintCalculatorAuthoredData> | null, AbortController];
}

const ReadAuthoring: FC<ReadAuthoringProps> = ({ authorResource }) => {
  const authoredData = authorResource[0]?.read();
  const translationResource = useGetData<TranslationData>(authoredData?.translation ?? '');
  if (!authoredData) return <></>;
  return (
    <Suspense fallback={<Spinner full />}>
      <PaintCalculator authoredData={authoredData} translationResource={translationResource} />
    </Suspense>
  );
};

const PaintCalculator: FC<PaintCalculatorProps> = ({ authoredData, translationResource }) => {
  const translationData = translationResource[0]?.read();
  const [pageLang, setPageLang] = useState<Locale>('en-US');

  const router = createHashRouter([
    {
      path: '/measurement-picker?',
      element: <MeasurementPicker />,
    },
    {
      path: '/quick/:space?/:paintType?/:unit?',
      element: <Quick />,
    },
    {
      loader: () => [authoredData],
      path: '/quick/help/interior/:paintType?/:unit?',
      element: <QuickHelpInterior />,
    },
    {
      loader: () => [authoredData],
      path: '/quick/help/exterior/:paintType?/:unit?',
      element: <QuickHelpExterior />,
    },
    {
      path: '/results/:calculation?/:space?/:paintType?/:unit?',
      loader: () => [authoredData],
      element: <Results />,
    },
    {
      path: '/custom/:space?/:paintType?/:unit?',
      element: <Custom />,
    },
    {
      loader: () => [authoredData],
      path: '/custom/help/interior/:paintType?/:unit?',
      element: <CustomHelpInterior />,
    },
    {
      loader: () => [authoredData],
      path: '/custom/help/exterior/:paintType?/:unit?',
      element: <CustomHelpExterior />,
    },
  ]);

  useEffect(() => {
    switch (document.documentElement.lang) {
      case 'es':
        setPageLang('es-US');
        break;
      case 'fr':
        setPageLang('fr-CA');
        break;
      default:
        setPageLang('en-US');
    }
  }, []);

  if (!authoredData) {
    return (
      <IntlProvider messages={translationData ?? getDictionary(pageLang)} locale={pageLang} defaultLocale='en'>
        <FormattedMessage description='Authored Data failed' defaultMessage='Authored Data not found' />
      </IntlProvider>
    );
  }

  return (
    <IntlProvider
      messages={translationData ?? getDictionary(authoredData.locale ?? pageLang)}
      locale={authoredData.locale ?? pageLang}
      defaultLocale='en'
    >
      <PaintCalculatorProvider>
        <div className='pc'>
          {router && (
            <>
              <section className='pc-supertitle'>
                <h2>
                  <span>
                    <FormattedMessage defaultMessage='PAINT CALCULATOR' />
                  </span>
                </h2>
              </section>
              <RouterProvider router={router} />
            </>
          )}
        </div>
      </PaintCalculatorProvider>
    </IntlProvider>
  );
};

const PaintCalculatorContainer: FC<PaintCalculatorContainerProps> = ({ slingModelAPI }) => {
  const authorResource = useGetData<PaintCalculatorAuthoredData>(`${slingModelAPI}.model.json`);
  return (
    <Suspense fallback={<Spinner full />}>
      <ReadAuthoring authorResource={authorResource} />
    </Suspense>
  );
};

export default PaintCalculatorContainer;
