import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import '../pages.scss';
import './quick.scss';
import Button from '../../components/button/button';
import InfoIcon from '../../components/info-icon/infoIcon';
import QuickDisclaimerWrapper from '../../components/disclaimer/quick-disclaimer-wrapper';
import TextInput from '../../components/text-input/text-input';
import Title from '../../components/title/title';
import { FaArrowLeftLong, FaCircleInfo } from 'react-icons/fa6';
import { FormattedMessage } from 'react-intl';
import { MdSwapHorizontalCircle } from 'react-icons/md';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { forceNumeric, roundDown } from '../../../shared/utils/forceNumeric';
import { quickArea, quickCoverage } from '../../calculation/quick';
import { useNavigate, useParams } from 'react-router-dom';
import { useReadParams } from '../../hooks/use-read-params';
import { useTranslations } from '../../hooks/use-translations';

const Quick: FC = () => {
  const translations = useTranslations();
  const [calculateClicked, setCalculateClicked] = useState(false);
  const [isError, setIsError] = useState(false);
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);
  const navigate = useNavigate();
  const params = useParams();

  const clearStore = useCallback(() => {
    setCalculateClicked(false);
    if (dispatch) {
      dispatch({ type: 'CLEAR_ALL' });
    }
  }, [dispatch]);

  const resetForm = useCallback(() => {
    setCalculateClicked(false);
    if (dispatch) {
      dispatch({ type: 'CLEAR_QUICK' });
    }
  }, [dispatch]);

  const handleInputChange = useCallback(
    (id: string, value: string | number) => {
      if (!dispatch) return;
      value = forceNumeric(value);
      switch (id) {
        case 'height':
          dispatch({ type: 'SET_QUICK_HEIGHT', height: value });
          break;
        case 'width':
          dispatch({ type: 'SET_QUICK_WIDTH', width: value });
          break;
        case 'doors': {
          const valueToUse = roundDown(value);
          dispatch({ type: 'SET_QUICK_DOORS', doors: valueToUse });
          break;
        }
        case 'windows': {
          const valueToUse = roundDown(value);
          dispatch({ type: 'SET_QUICK_WINDOWS', windows: valueToUse });
          break;
        }
        default:
          console.warn(`Unknown id`, id, value);
      }
    },
    [dispatch],
  );

  const calculateCallback = useCallback(() => {
    setCalculateClicked(true);
    const area = store.quick?.area?.area ?? 0;
    if (!isError && area > 0) {
      navigate(`/results/quick/${store.space}/${store.paintType}/${store.unit}`);
    }
  }, [isError, navigate, store]);

  useReadParams({ calculation: 'quick', dispatch, navigate, params, store });

  useEffect(() => {
    if (dispatch) {
      const [totalArea, totalAreaError] = quickArea(
        store.unit ?? 'imperial',
        forceNumeric(store.quick?.height),
        0,
        forceNumeric(store.quick?.width),
        0,
        forceNumeric(store.quick?.doors),
        forceNumeric(store.quick?.windows),
      );
      const [ceilingArea, ceilingError] = quickArea(
        store.unit ?? 'imperial',
        forceNumeric(store.quick?.width) / 4,
        0,
        forceNumeric(store.quick?.width) / 4,
        0,
        0,
        0,
      );
      if (!!store.quick?.isError !== totalAreaError) {
        dispatch({ type: 'SET_QUICK_IS_ERROR', isError: totalAreaError });
      } else {
        dispatch({
          type: 'SET_QUICK_AREA',
          area: totalArea,
        });
        // Only do the coverage calculation if the area is not in error
        if (!totalAreaError) {
          const [coverage, coverageError] = quickCoverage(
            store.unit ?? 'imperial',
            store.quick?.area?.area ?? 0,
            store.paintType ?? 'paint',
            store.space ?? 'interior',
          );
          if (!store.quick?.isError && coverageError) {
            dispatch({ type: 'SET_QUICK_IS_ERROR', isError: coverageError });
          } else {
            dispatch({
              type: 'SET_QUICK_COVERAGE',
              coverage: coverage,
            });

            if (!store.quick?.isError && ceilingError) {
              dispatch({ type: 'SET_QUICK_IS_ERROR', isError: ceilingError });
            } else {
              dispatch({
                type: 'SET_QUICK_CEILING_AREA',
                area: ceilingArea,
              });
              // Only do the coverage calculation if the area is not in error
              if (!ceilingError) {
                const [ceilingCoverage, ceilingCoverageError] = quickCoverage(
                  store.unit ?? 'imperial',
                  store.quick?.ceiling?.area ?? 0,
                  store.paintType ?? 'paint',
                  store.space ?? 'interior',
                );
                if (!!store.quick?.isError !== ceilingCoverageError) {
                  dispatch({ type: 'SET_QUICK_IS_ERROR', isError: ceilingCoverageError });
                } else {
                  dispatch({
                    type: 'SET_QUICK_CEILING_COVERAGE',
                    coverage: ceilingCoverage,
                  });
                }
              }
            }
          }
        }
      }
    }
  }, [dispatch, store]);

  useEffect(() => {
    const area = store.quick?.area?.area ?? 0;
    setIsError(!!store.quick?.isError || calculateClicked ? area <= 0 : area < 0);
  }, [calculateClicked, store]);

  return (
    <div className='pc-quick'>
      <section className='pc-quick__title'>
        <Title
          text={
            <FormattedMessage
              defaultMessage='{location} QUICK CALCULATION'
              values={{
                location: store.space === 'interior' ? translations.INTERIOR : translations.EXTERIOR,
              }}
            />
          }
        >
          <InfoIcon
            route={`/quick/help/${store.space}`}
            tooltipText={
              <FormattedMessage
                defaultMessage='View help with {location} calculations'
                values={{
                  location: store.space === 'interior' ? translations.interior : translations.exterior,
                }}
              />
            }
          >
            <FaCircleInfo />
          </InfoIcon>
        </Title>
      </section>
      <div className='pc-quick__inset'>
        <section className='pc-quick__start-over'>
          <Button style='link' route='/measurement-picker' isInternal callback={clearStore}>
            <FaArrowLeftLong />
            <FormattedMessage defaultMessage='Start Over' />
          </Button>
        </section>
        <section className='pc-quick__form'>
          <div className='pc-quick__input-grid pc-quick__input-grid--no-margin'>
            <h4>
              <FormattedMessage defaultMessage='TOTAL WALL AREA' />
            </h4>
            <TextInput
              id='height'
              isError={isError}
              inputMode='numeric'
              type='text'
              supLabel={translations.Height}
              subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
              value={store.quick?.height}
              callBack={handleInputChange}
            />
            <TextInput
              id='width'
              isError={isError}
              inputMode='numeric'
              type='text'
              supLabel={translations.Length}
              subLabel={store.unit === 'imperial' ? translations.Feet : translations.Meters}
              value={store.quick?.width}
              callBack={handleInputChange}
            />
          </div>
          {isError && (
            <section className='pc-quick__error'>
              <FormattedMessage defaultMessage='Please check your measurements.' />
            </section>
          )}
          <div className='pc-quick__input-grid'>
            <h4>
              <FormattedMessage defaultMessage='ADD DOORS AND WINDOWS' />
            </h4>
            <TextInput
              id='doors'
              isError={isError}
              inputMode='numeric'
              type='text'
              supLabel={translations.Doors}
              subLabel={translations.Count}
              value={store.quick?.doors?.toString() ?? ''}
              callBack={handleInputChange}
            />
            <TextInput
              id='windows'
              isError={isError}
              inputMode='numeric'
              type='text'
              supLabel={translations.Windows}
              subLabel={translations.Count}
              value={store.quick?.windows?.toString() ?? ''}
              callBack={handleInputChange}
            />
          </div>
        </section>
        {isError && (
          <section className='pc-quick__error'>
            <FormattedMessage defaultMessage='Please check the counts.' />
          </section>
        )}
        <section className='pc-quick__buttons'>
          <Button style='button' callback={resetForm}>
            <FormattedMessage defaultMessage='Reset' />
          </Button>
          <Button
            style='button'
            isInternal
            route={`/quick/${store.space === 'interior' ? 'exterior' : 'interior'}/${store.paintType}/${store.unit}`}
          >
            <MdSwapHorizontalCircle />
            {store.space === 'interior' ? (
              <FormattedMessage defaultMessage='Exterior' />
            ) : (
              <FormattedMessage defaultMessage='Interior' />
            )}
          </Button>
          <Button style='button' isInverse isInternal callback={calculateCallback}>
            <FormattedMessage defaultMessage='Calculate' />
          </Button>
        </section>
      </div>
      <QuickDisclaimerWrapper />
    </div>
  );
};

export default Quick;
