import enCA from '../compiled/en-CA.json';
import enUS from '../compiled/en-US.json';
import esUS from '../compiled/es-US.json';
import frCA from '../compiled/fr-CA.json';
import type { ResolvedIntlConfig } from '@formatjs/intl/src/types';
import { Locale } from '../../../shared/types/localeTypes';

export const getDictionary = (locale: Locale): ResolvedIntlConfig['messages'] => {
  switch (locale) {
    case 'es-US':
      return esUS;
    case 'en-CA':
      return enCA;
    case 'fr-CA':
      return frCA;
    default:
      return enUS;
  }
};
