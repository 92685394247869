import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import '../pages.scss';
import './custom.scss';
import Button from '../../components/button/button';
import CustomDisclaimerWrapper from '../../components/disclaimer/custom-disclaimer-wrapper';
import InfoIcon from '../../components/info-icon/infoIcon';
import Title from '../../components/title/title';
import Wall from '../../components/wall/wall';
import { FaArrowLeftLong, FaCircleInfo } from 'react-icons/fa6';
import { FormattedMessage } from 'react-intl';
import { MdSwapHorizontalCircle } from 'react-icons/md';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { useNavigate, useParams } from 'react-router-dom';
import { useReadParams } from '../../hooks/use-read-params';
import { useTranslations } from '../../hooks/use-translations';

const Custom: FC = () => {
  const [calculateClicked, setCalculateClicked] = useState(false);
  const translations = useTranslations();
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);
  const navigate = useNavigate();
  const params = useParams();
  const [isError, setIsError] = useState(false);

  const clearStore = () => {
    setCalculateClicked(false);
    if (dispatch) {
      dispatch({ type: 'CLEAR_ALL' });
    }
  };

  const addCustomWall = useCallback(() => {
    if (dispatch) {
      dispatch({ type: 'ADD_CUSTOM_WALL' });
    }
  }, [dispatch]);

  const resetCustomForm = useCallback(() => {
    setCalculateClicked(false);
    if (dispatch) {
      dispatch({ type: 'RESET_FORM' });
    }
  }, [dispatch]);

  const calculateCallback = useCallback(() => {
    setCalculateClicked(true);
    const area = store.custom?.area?.area ?? 0;
    const hasWallErrors = Object.entries(store.custom?.wall ?? {}).some(([_, wallData]) => wallData.isError === true);
    if (!hasWallErrors && !isError && area > 0) {
      navigate(`/results/custom/${store.space}/${store.paintType}/${store.unit}`);
    }
  }, [isError, navigate, store]);

  useEffect(() => {
    if (Object.keys(store.custom?.wall ?? {}).length === 0 && dispatch) {
      dispatch({ type: 'ADD_CUSTOM_WALL' });
    }
  }, [store, dispatch]);

  useEffect(() => {
    const area = store.custom?.area?.area ?? 0;
    setIsError(!!store.custom?.isError || calculateClicked ? area <= 0 : area < 0);
  }, [store.custom, isError, calculateClicked]);

  useReadParams({ calculation: 'custom', dispatch, navigate, params, store });

  return (
    <div className='pc-custom'>
      <section className='pc-custom__title'>
        <Title
          text={
            <FormattedMessage
              defaultMessage='{location} CUSTOM CALCULATION'
              values={{
                location: store.space === 'interior' ? translations.INTERIOR : translations.EXTERIOR,
              }}
            />
          }
        >
          <InfoIcon
            route={`/custom/help/${store.space}`}
            tooltipText={
              <FormattedMessage
                defaultMessage='View help with {location} calculations'
                values={{
                  location: store.space === 'interior' ? translations.interior : translations.exterior,
                }}
              />
            }
          >
            <FaCircleInfo />
          </InfoIcon>
        </Title>
      </section>
      <div className='pc-custom__inset'>
        <section className='pc-custom__start-over'>
          <Button style='link' route='/measurement-picker' isInternal callback={clearStore}>
            <FaArrowLeftLong />
            <FormattedMessage defaultMessage='Start Over' />
          </Button>
        </section>
        {Object.entries(store.custom?.wall ?? {}).map(([wallId]) => (
          <section className='pc-custom__wall' key={wallId}>
            <Wall wallId={wallId} isError={isError || store.custom?.wall?.[wallId]?.isError} />
          </section>
        ))}
        <section className='pc-custom__add-wall-btn'>
          <Button style='button' callback={addCustomWall}>
            {store.space === 'interior' ? (
              <FormattedMessage defaultMessage='+ Wall' />
            ) : (
              <FormattedMessage defaultMessage='+ Side' />
            )}
          </Button>
        </section>
        <section className='pc-custom__button-group'>
          <Button style='button' callback={resetCustomForm}>
            <FormattedMessage defaultMessage='Reset' />
          </Button>
          <Button
            style='button'
            isInternal
            route={`/custom/${store.space === 'interior' ? 'exterior' : 'interior'}/${store.paintType}/${store.unit}`}
          >
            <MdSwapHorizontalCircle />
            {store.space === 'interior' ? (
              <FormattedMessage defaultMessage='Exterior' />
            ) : (
              <FormattedMessage defaultMessage='Interior' />
            )}
          </Button>
          <Button style='button' isInverse isInternal callback={calculateCallback}>
            <FormattedMessage defaultMessage='Calculate' />
          </Button>
        </section>
      </div>
      <CustomDisclaimerWrapper />
    </div>
  );
};

export default Custom;
