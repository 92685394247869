import React, { FC } from 'react';
import './custom-help.scss';
import Button from '../../../components/button/button';
import InfoIcon from '../../../components/info-icon/infoIcon';
import { FaTimesCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PaintCalculatorAuthoredData } from '../../../../shared/types/paint-calculator/types';
import { useLoaderData } from 'react-router-dom';
import { useTranslations } from '../../../hooks/use-translations';

const CustomHelpExterior: FC = () => {
  const [authoredData] = useLoaderData() as [PaintCalculatorAuthoredData];
  const translations = useTranslations();

  return (
    <article>
      <section className='pc-custom-help-title-section'>
        <h2>
          <FormattedMessage
            defaultMessage='Help With {location} Calculations'
            values={{ location: <FormattedMessage defaultMessage='Exterior' /> }}
          />
        </h2>
        <InfoIcon
          route='/custom/exterior'
          tooltipText={
            <FormattedMessage
              defaultMessage='Return to {location} form'
              values={{ location: <FormattedMessage defaultMessage='exterior' /> }}
            />
          }
        >
          <FaTimesCircle />
        </InfoIcon>
      </section>
      <section className='pc-custom-help-content-section'>
        <h3>
          <FormattedMessage defaultMessage='Walls' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Exterior Custom Walls')?.fileReference
          }
          alt={translations.WallImage}
        />
        <p>
          <FormattedMessage
            defaultMessage='Height:Select one side of your space and measure from the 
              ground to the top of a standard wall to find height.
              Width:For width, measure from one bottom corner to the other bottom corner.
              Peak Height:If you have an angled ceiling (as with a pitched roof or cathedral ceiling), 
              you will need to determine peak height.
              Measure the distance from the top of the standard wall height to the tallest point of the peak.'
          />
        </p>
        <h3>
          <FormattedMessage defaultMessage='Texture' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Exterior Custom Texture')
              ?.fileReference
          }
          alt={translations.TextureImage}
        />
        <p>
          <FormattedMessage
            defaultMessage='Each wall texture type requires a different amount of paint. 
              Consider the type of wall texture in your space using the following examples as a guide.
              Smooth:any smooth texture
              Medium:Similar to rice kernels
              Coarse: Similar to un-ground peppercorns or sea salt'
          />
        </p>
        <h3>
          <FormattedMessage defaultMessage='Windows' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Exterior Custom Windows')
              ?.fileReference
          }
          alt={translations.WindowImage}
        />
        <p>
          <FormattedMessage
            defaultMessage='If there is a window on the side you have selected, be sure 
              to measure from inside trim edge to inside trim edge. Measure your trim width separately.'
          />
        </p>
        <h3>
          <FormattedMessage defaultMessage='Doorways / Openings' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Exterior Custom Doorways')
              ?.fileReference
          }
          alt={translations.DoorwayImage}
        />
        <p>
          <FormattedMessage
            defaultMessage='If there is a door on the wall you have selected, 
              be sure to write down the measurements of the door itself, excluding trim.'
          />
        </p>
        <h3>
          <FormattedMessage defaultMessage='Baseboards / Crown Molding' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Exterior Custom Baseboards')
              ?.fileReference
          }
          alt={translations.BaseboardImage}
        />
        <p>
          <FormattedMessage
            defaultMessage='Baseboards are often painted the same color as other trim.
              Enter the height of your baseboards as a separate measurement 
              if you plan to paint your baseboards.
              Crown moldingis often painted the same color as other trim. 
              Enter the height of your crown molding as a separate measurement if you plan to paint your crown molding.'
          />
        </p>
        <h3>
          <FormattedMessage defaultMessage='Trim' />
        </h3>
        <img
          src={
            authoredData.imageSelector?.find((ImageData) => ImageData.title === 'Exterior Custom Trim')?.fileReference
          }
          alt={translations.TrimImage}
        />
        <p>
          <FormattedMessage
            defaultMessage='Some rooms may have other trim such as a chair or plate rail, 
              while other rooms may have a use for trim beyond baseboards, 
              encasing other areas such as a bookcase or nook.'
          />
        </p>
      </section>
      <section className='pc-custom-help-button'>
        <Button style='button' route='/custom/exterior' isInternal>
          <FormattedMessage defaultMessage='Close' />
        </Button>
      </section>
    </article>
  );
};

export default CustomHelpExterior;
