import React, { FC, useContext, useEffect, useState } from 'react';
import './measurement-picker.scss';
import Button from '../../components/button/button';
import Disclaimer from '../../components/disclaimer/disclaimer';
import Title from '../../components/title/title';
import { FormattedMessage } from 'react-intl';
import { MEASUREMENT_PICKER_ALL } from '../../../shared/types/paint-calculator/types';
import { PaintCalculatorDispatch, PaintCalculatorState } from '../../context/paint-calculator.context';
import { useNavigate } from 'react-router-dom';

const MeasurementPicker: FC = () => {
  const [isError, setIsError] = useState(false);
  const store = useContext(PaintCalculatorState);
  const dispatch = useContext(PaintCalculatorDispatch);

  const navigate = useNavigate();

  const measurementPickerCallback = (buttonValue: MEASUREMENT_PICKER_ALL) => {
    if (dispatch) {
      switch (buttonValue) {
        case 'imperial':
        case 'metric':
          dispatch({ type: 'SET_UNITS_OF_MEASUREMENT', unit: buttonValue });
          break;
        case 'paint':
        case 'primer':
          dispatch({ type: 'SET_PAINT_TYPE', paintType: buttonValue });
          break;
        case 'interior':
        case 'exterior':
          dispatch({ type: 'SET_SPACE', space: buttonValue });
          break;
        case 'quick':
        case 'custom':
          dispatch({ type: 'SET_CALCULATION', calculation: buttonValue });
          break;
        default:
          console.error('Unknown button value', buttonValue);
      }
    }
  };

  useEffect(() => {
    setIsError(store.unit === undefined && store.calculation !== undefined);
    if (store.unit !== undefined && store.calculation !== undefined) {
      navigate(`/${store.calculation}/${store.space ?? 'interior'}`);
    }
  }, [isError, navigate, store]);

  return (
    <div className='pc-measurement-picker'>
      <section className='pc-measurement-picker__title'>
        <Title text={<FormattedMessage defaultMessage='TELL US ABOUT YOUR PROJECT' />} />
      </section>
      <section className='pc-measurement-picker__container'>
        <div className='pc-measurement-picker__button-grid'>
          <h4>
            <FormattedMessage defaultMessage='UNITS OF MEASUREMENT' />
          </h4>
          <Button
            style='button'
            isSelected={store.unit === 'imperial'}
            callback={() => measurementPickerCallback('imperial')}
          >
            <FormattedMessage defaultMessage='Feet' />
          </Button>
          <Button
            style='button'
            isSelected={store.unit === 'metric'}
            callback={() => measurementPickerCallback('metric')}
          >
            <FormattedMessage defaultMessage='Meters' />
          </Button>
        </div>
        <div className='pc-measurement-picker__button-grid'>
          <h4>
            <FormattedMessage defaultMessage='PAINT TYPE' />
          </h4>
          <Button
            style='button'
            isSelected={store.paintType === 'paint'}
            callback={() => measurementPickerCallback('paint')}
          >
            <FormattedMessage defaultMessage='Color' />
          </Button>
          <Button
            style='button'
            isSelected={store.paintType === 'primer'}
            callback={() => measurementPickerCallback('primer')}
          >
            <FormattedMessage defaultMessage='Primer' />
          </Button>
        </div>
        <div className='pc-measurement-picker__button-grid'>
          <h4>
            <FormattedMessage defaultMessage='SPACE' />
          </h4>
          <Button
            style='button'
            isSelected={store.space === 'interior'}
            callback={() => measurementPickerCallback('interior')}
          >
            <FormattedMessage defaultMessage='Interior' />
          </Button>
          <Button
            style='button'
            isSelected={store.space === 'exterior'}
            callback={() => measurementPickerCallback('exterior')}
          >
            <FormattedMessage defaultMessage='Exterior' />
          </Button>
        </div>
        <div className='pc-measurement-picker__button-grid'>
          <h4>
            <FormattedMessage defaultMessage='CALCULATION' />
          </h4>
          <Button
            style='button'
            isSelected={store.calculation === 'quick'}
            callback={() => measurementPickerCallback('quick')}
          >
            <FormattedMessage defaultMessage='Quick' />
          </Button>
          <Button
            style='button'
            isSelected={store.calculation === 'custom'}
            callback={() => measurementPickerCallback('custom')}
          >
            <FormattedMessage defaultMessage='Custom' />
          </Button>
        </div>
        {isError && (
          <section className='pc-measurement-picker__error'>
            <FormattedMessage defaultMessage='Please choose a unit of measurement above to continue.' />
          </section>
        )}
      </section>
      <section className='pc-measurement-picker__container'>
        <Disclaimer>
          <FormattedMessage defaultMessage='The paint calculator is intended to provide the user with the approximate amount of product needed for a project. Product requirements will vary according to factors such as application method and surface condition.' />
        </Disclaimer>
      </section>
    </div>
  );
};

export default MeasurementPicker;
