import React, { Children, FC, JSX, MouseEvent, ReactNode } from 'react';
import './button.scss';
import { Link, useInRouterContext } from 'react-router-dom';

export interface ButtonProps {
  style: 'link' | 'button';
  wrapperElement?: keyof JSX.IntrinsicElements;
  route?: string;
  isInverse?: boolean;
  isInternal?: boolean;
  isSelected?: boolean;
  callback?: () => void;
  children?: ReactNode | ReactNode[];
}

const Button: FC<ButtonProps> = ({
  style,
  wrapperElement,
  route,
  isInverse,
  isInternal,
  isSelected,
  callback,
  children,
}) => {
  const childrenArray = Array.isArray(children) ? children : [children];
  const childrenNodes = Children.map(childrenArray, (child, index) => <span key={index}>{child}</span>);
  const isInternalMod = !!isInternal; // force boolean
  const HTMLElement = wrapperElement ?? 'div';

  const inRouterContext = useInRouterContext();

  const handleCallback = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    e.stopPropagation();
    if (callback) callback();
  };

  return (
    <HTMLElement
      className={`pc-button pc-button--${style}${isInverse ? ' pc-button--is-inverse' : ''}${
        isSelected ? ' pc-button--is-selected' : ''
      }`}
    >
      {style === 'link' && route && (
        <>
          {isInternalMod && inRouterContext && (
            <Link className='pc-button__link' to={route} onClick={handleCallback}>
              {childrenNodes}
            </Link>
          )}
          {(!isInternalMod || !inRouterContext) && (
            <a className='pc-button__link' href={route} onClick={handleCallback}>
              {childrenNodes}
            </a>
          )}
        </>
      )}
      {style === 'button' && route && (
        <>
          {isInternalMod && inRouterContext && (
            <Link className='pc-button__button' to={route} onClick={handleCallback}>
              {childrenNodes}
            </Link>
          )}
          {(!isInternalMod || !inRouterContext) && (
            <a className='pc-button__button' href={route} onClick={handleCallback}>
              {childrenNodes}
            </a>
          )}
        </>
      )}
      {style === 'button' && !route && (
        <button className='pc-button__button' onClick={handleCallback}>
          {childrenNodes}
        </button>
      )}
    </HTMLElement>
  );
};

export default Button;
