import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import PaintCalculator from './paint-calculator';

const container: HTMLElement | null = document.querySelector('.cmp-paint-calculator-root');

if (container) {
  const slingModelAPI = container.dataset.resourcePath;
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <PaintCalculator slingModelAPI={slingModelAPI ?? ''} />
    </StrictMode>,
  );
}
