import React, { ChangeEvent, FC, KeyboardEvent, ReactNode, useRef } from 'react';
import './checkbox.scss';
import { FaRegSquare, FaSquareCheck } from 'react-icons/fa6';

export interface CheckboxProps {
  id: string;
  isError?: boolean;
  isChecked: boolean;
  callback: () => void;
  children: ReactNode;
}

const Checkbox: FC<CheckboxProps> = ({ id, isError, isChecked, callback, children }) => {
  const checkboxRef = useRef<HTMLInputElement | null>(null);
  const handleKeyUp = (e: KeyboardEvent<HTMLLabelElement>) => {
    if (e.key === 'Enter') checkboxRef.current?.click();
  };
  const handleCallback = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (callback) callback();
  };
  return (
    <label
      className={`pc-checkbox${isError ? ' pc-checkbox--is-error' : ''}`}
      htmlFor={id}
      tabIndex={0}
      onKeyUp={handleKeyUp}
    >
      <input id={id} type='checkbox' ref={checkboxRef} checked={isChecked} onChange={handleCallback} />
      <span>{isChecked ? <FaSquareCheck /> : <FaRegSquare />}</span>
      <span>{children}</span>
    </label>
  );
};

export default Checkbox;
