import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import './select.scss';
import { DEBOUNCE_TIME } from '../../constants/constants';

export interface SelectProps {
  id: string;
  isError?: boolean;
  supLabel?: string | ReactNode;
  options: Array<{ value: string; label: string }>;
  value: string;
  callBack?: (id: string, value: string) => void;
}

const Select: FC<SelectProps> = ({ id, isError, supLabel, options, value, callBack }) => {
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(e.target.value);
  };

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setSelectValue(value);
    }
  }, [value]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (callBack && selectValue) callBack(id, selectValue);
    }, DEBOUNCE_TIME);
    return () => {
      clearTimeout(timer);
    };
  }, [callBack, id, selectValue]);

  return (
    <label className={`pc-select${isError ? ' pc-select--is-error' : ''}`} htmlFor={id}>
      {supLabel && <span>{supLabel}</span>}
      <select onChange={handleChange} value={selectValue ?? ''}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default Select;
